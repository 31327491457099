<template>
    <div class="template-detail">
       <v-row>
           <v-col xs="12" :lg="template.canal == 'VMS' ? '12' : '6'" >
               <!-- <h1 class="mb-2 primary--text" v-if="inCampaignTemplatePage">{{ $t('selectedTemplate') }}</h1>
               <h3 class="mb-6 mt-4 darkgrey--text" v-if="inCampaignTemplatePage">{{ template.name }}</h3>
               <h1 class="mb-6 primary--text" v-else-if="!inTemplatePage">{{ template.name }}</h1> -->

               <p :class="paramsShown && (typeof paramsShown.subject !== 'undefined') ? 'mb-0' : ''" v-if="paramsShown && ((typeof paramsShown.senderName !== 'undefined' && paramsShown.senderName !== '') || (typeof paramsShown.sender !== 'undefined' && paramsShown.sender !== ''))">
                   <b>{{ $t('from') }} : </b> <span v-if="(typeof paramsShown.senderName !== 'undefined')">{{paramsShown.senderName}}</span> <span v-if="(typeof paramsShown.sender !== 'undefined')">&#60;{{paramsShown.sender}}&#62;</span>
               </p>
               <p v-if="paramsShown && (typeof paramsShown.subject !== 'undefined')">
                   <b>{{ $t('subject') }} : </b> {{paramsShown.subject}}
               </p>
               <p v-if="templateShown && (typeof templateShown.link !== 'undefined')">
                   <b>{{ $t('link') }} : </b> {{templateShown.link}}
               </p>
               <p v-if="paramsShown && ((template.canal == 'EMAIL' && (!paramsShown.hasOwnProperty('subject') || !paramsShown.hasOwnProperty('senderName') || !paramsShown.hasOwnProperty('sender'))) || template.canal == 'SMS' && !paramsShown.hasOwnProperty('sender'))">
                    <v-alert color="error" class="white--text">
                        <v-row align="center">
                            <v-col class="grow">
                            {{$t("paramsAreMissing")}}
                            </v-col>
                            <!-- <v-col class="shrink">
                                <v-btn small outlined @click='getForm("/template/"+template.id+"/getFormTemplate?newVersion=false")' color="white">
                                    <v-icon small class="mr-2">$warning_l</v-icon>
                                    {{$t('editParams')}}
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-alert>
               </p>
               
               <div v-if="templateShown" class="template-show">
                    <div v-if="templateShown.mp3">
                        <span><strong>{{$t('title')}}</strong> : {{templateShown.mp3.title}}</span>
                        <audio id="audioPreview" v-if="templateShown.mp3 && templateShown.mp3.url" controls controlsList="nodownload" :src="templateShown.mp3.url" style="width: 100%;"></audio>
                    </div>
                    <v-card v-else max-width="600" style="margin-left:auto; margin-right:auto;">
                        <iframe v-if="templateShown.html" :id="'templateIframeHtml'+keyIdTpl" :srcdoc="templateShown.html" @load="resizeIframe('templateIframeHtml'+keyIdTpl)" style="border: none; pointer-events: none;"></iframe>
                        <v-img v-if="templateShown.image && templateShown.image.url" width="100%" :src="templateShown.image.url"></v-img>
                        <embed v-if="templateShown.pdf && templateShown.pdf.url" :src="templateShown.pdf.url + '?#view=FitH&scrollbar=0&toolbar=0&navpanes=0'" width="100%" height="541" type="application/pdf">
                        <v-card-text v-if="templateShown.text">
                            <div v-html="templateShown.text"></div>
                        </v-card-text>
                    </v-card>

                    <div class="action-buttons" v-if="(!campaign || !['PROGRAMMED', 'COMPLETED'].includes(campaign.status)) &&  $store.state.auth.currentUser.rights[0].entities.includes(template.entity_id.id)">
                        <!-- <div v-if="(inTemplatePage || inCampaignTemplatePage) && !inWorkflow && !inStepper && $func.hasRight('template/update')">
                            <v-btn v-if="templateShown.html" fab small color="primary" :to="{ name: 'TemplateCreateEmail', params: params}">
                                <v-icon small>$pencil_d</v-icon>
                            </v-btn>
                            <v-btn v-else-if="templateShown.pdf" fab small color="primary" @click="$emit('getForm', '/template/' + template.id + '/getEditor', true)">
                                <v-icon small>$pencil_d</v-icon>
                            </v-btn>
                            <v-btn v-else fab small color="primary" @click="$emit('getForm', '/template/' + template.id + '/getEditor')">
                                <v-icon small>$pencil_d</v-icon>
                            </v-btn>
                        </div>
                        <div v-else-if="!inTemplatePage && !inCampaignTemplatePage && campaign && $func.hasRight('template/update')">
                            <v-btn fab small color="primary" @click="$emit('getForm', '/template/' + template.id + '/getForm?retroplanning_id=' + campaign.id + '&formName=' + template.canal, ['EMAIL', 'MAILING', 'ENVELOPE'].includes(template.canal) ? true : false, campaign)">
                                <v-icon small>$pencil_d</v-icon>
                            </v-btn>
                        </div> -->
                    </div>
                </div>
           </v-col>
           <v-col  xs="12" lg="6"  class="content-template" v-if="template.canal !== 'VMS'">
                <div class="buttons mb-3 text-right" v-if="inCampaignTemplatePage">
                    <v-btn v-if="template.canal !== 'MAILING' && template.canal !== 'ENVELOPE'" @click.stop="showCompiledTemplate = !showCompiledTemplate" depressed small class="mr-2" color="primary">{{ !showCompiledTemplate ? $t('compiled_overview') : $t('not_compiled_overview') }}</v-btn>
                    <!-- <v-btn v-if="(template.canal === 'MAILING' || template.canal === 'ENVELOPE') && !inStepper" @click.stop="showEnvelopeTemplateFunction()" depressed small class="mr-2" color="primary">{{ showEnvelopeTemplate ? $t('show_mailing_template') : $t('show_envelope_template') }}</v-btn> -->
                    <!-- <v-btn :disabled="campaign.status != 'DRAFT'" v-if="!isLastVersion && $func.hasRight('retroplanning/configure')"  depressed class="mr-2" @click.stop="changeToLastVersion()" color="primary" small>{{$t('use_last_version')}}</v-btn> -->
                    <!-- <v-btn :disabled="!['DRAFT', 'PROVIDER_REFUSAL', 'ON_GOING'].includes(campaign.status)" v-if="!inWorkflow && !inStepper && $func.hasRight('retroplanning/configure')" depressed small class="mr-2" color="primary" @click="$emit('edit')">{{ $t('change_template') }}</v-btn> -->
                </div>
                <div v-if="template.variables && template.variables.values && template.variables.values.length == 0">
                    <v-alert class="mt-2 mb-3 mb-0" border="left" color="info" text>
                        {{ $t("noVariableSet") }}
                    </v-alert>
                </div>
               <div v-for="(variables, category) in variablesByCategories" :key="'variables_' + category" class="mb-6">    
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr class="heading">
                                    <th class="text-left" style="width:325px;">{{ $t('variable') }} {{$t(category)}}</th>
                                    <th class="text-left" v-if="(inCampaignTemplatePage)">
                                        {{ $t('value') }}
                                    </th>
                                    <th class="text-right" v-if="(inCampaignTemplatePage)">
                                        <v-btn v-if="category == 'contact' || category == 'rdv'" @click="$emit('customRefresh')" text dense class="square">
                                            <v-icon small>$refresh_r</v-icon>
                                        </v-btn>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(variable, variableName) in variables" :key="'variable_' + variableName">
                                    <td>
                                        <b>{{ $t(variableName) }}</b><br/>
                                        <span class="darkgrey--text" v-html="'{{ ' + variableName + ' }}'"></span>
                                    </td>
                                    <td v-if="inCampaignTemplatePage">
                                        <v-hover v-if="!['contact', 'rdv', 'places', 'entities', 'entrypoints', 'sender', 'landing_pagev2'].includes(category)" v-slot="{ hover }">
                                            <div :class="'rounded text-to-input ' + (hover ? 'active' : '')" @click="showInput(variableName)" v-click-outside="{ handler: saveOtherInput, closeConditional }">
                                                <div class="value-variable">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span  v-bind="attrs" v-on="on">
                                                                <v-icon class="mr-3" :color="variable.value !== null && variable.value !== '' ? (variable.inherit ? 'grey' : 'info') : 'error'">{{ variable.value !== null && variable.value !== '' ? '$map_marker_edit_s' : '$map_marker_time_s' }}</v-icon>
                                                            </span>
                                                        </template>
                                                        <span>{{ (variable.value !== null && variable.value !== "") ? (variable.inherit ? $t('variableHeritedTooltip') : $t('variableUpdatedTooltip')) : $t('variableNotSettedTooltip') }}</span>
                                                    </v-tooltip>
                                                    <div style="width: 100%;">
                                                        <span v-if="inputDisplayed === variableName">
                                                            <v-text-field class="pt-0 mt-O" v-model="variable.value" v-on:keyup.enter="saveInput" hide-details autofocus></v-text-field>
                                                        </span>
                                                        <span v-else-if="variable.value !== null" v-html="variable.value"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-hover>
                                        <v-hover v-else-if="['sender'].includes(category)">
                                            <div :class="'rounded text-to-input '" v-if="template.canal == 'EMAIL' && variableName == 'sender.sender'"  >
                                                <div class="value-variable">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span  v-bind="attrs" v-on="on">
                                                                <v-icon class="mr-3" :color="variable.value !== null && variable.value !== '' ? (variable.inherit ? 'grey' : 'info') : 'error'">{{ variable.value !== null && variable.value !== '' ? '$map_marker_edit_s' : '$map_marker_time_s' }}</v-icon>
                                                            </span>
                                                        </template>
                                                        <span>{{ (variable.value !== null && variable.value !== "") ? (variable.inherit ? $t('variableHeritedTooltip') : $t('variableUpdatedTooltip')) : $t('variableNotSettedTooltip') }}</span>
                                                    </v-tooltip>
                                                    <div style="width: 100%;">
                                                        <v-select :items="template[category]" v-model="variable.value" @change="saveSenderMail(variable.value)" dense filled hide-details :no-data-text="$t('no-data-text')">
                                                        </v-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="'rounded text-to-input '" v-else   @click.stop="showInput(variableName)" >
                                                <div class="value-variable">
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <span  v-bind="attrs" v-on="on">
                                                                <v-icon class="mr-3" :color="variable.value !== null && variable.value !== '' ? (variable.inherit ? 'grey' : 'info') : 'error'">{{ variable.value !== null && variable.value !== '' ? '$map_marker_edit_s' : '$map_marker_time_s' }}</v-icon>
                                                            </span>
                                                        </template>
                                                        <span>{{ (variable.value !== null && variable.value !== "") ? (variable.inherit ? $t('variableHeritedTooltip') : $t('variableUpdatedTooltip')) : $t('variableNotSettedTooltip') }}</span>
                                                    </v-tooltip>
                                                    <div style="width: 100%;">
                                                        <span v-if="inputDisplayed === variableName">
                                                            <span  style="display:flex; align-items:center;">
                                                                <v-custom-variable-text-field :varMode="true" :checkMessageSender="true" :blockMoveCursor="true" :field="{ rules: [{ 'fonction': 'messageSender', 'params': [] }] }" :model="variable" :fieldName="'value'" @disableSaveButton="disabledSaveButton = false;" @enableSaveButton="disabledSaveButton = true;"></v-custom-variable-text-field>
                                                                <v-btn :disabled="!disabledSaveButton" small depressed color="success" @click.stop="saveInput" class="square ml-2">
                                                                    <v-icon small>$check_l</v-icon>
                                                                </v-btn>
                                                            </span>
                                                        </span>
                                                        <span v-else-if="variable.value !== null" v-html="variable.value"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-hover>
                                        <div v-else-if="['places', 'entities', 'entrypoints'].includes(category)" class="rounded text-to-input">
                                            <div class="value-variable">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span  v-bind="attrs" v-on="on">
                                                            <v-icon class="mr-3" :color="variable.value !== null && variable.value !== '' ? (variable.inherit ? 'grey' : 'info') : 'error'">{{ variable.value !== null && variable.value !== '' ? '$map_marker_edit_s' : '$map_marker_time_s' }}</v-icon>
                                                        </span>
                                                    </template>
                                                    <span>{{ (variable.value !== null && variable.value !== "") ? (variable.inherit ? $t('variableHeritedTooltip') : $t('variableUpdatedTooltip')) : $t('variableNotSettedTooltip') }}</span>
                                                </v-tooltip>
                                                <div style="width: 100%;">
                                                    <v-select :items="category === 'entrypoints' ? template[category][variable.type] : template[category]" v-model="variable.value" @change="saveInputSelect(variable.value, category, variable.number, variable.type ? variable.type : null)" dense filled hide-details :no-data-text="$t('no-data-text')">
                                                        <template v-slot:prepend-item v-if="formUrl && category === 'entrypoints' && $func.hasRight('entrypoint/add')">
                                                            <v-list-item ripple @click="getFormToAdd(category, variable.type)">
                                                            <v-list-item-content>
                                                                <v-list-item-title> {{ $t("createNew") }} </v-list-item-title>
                                                            </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider class="mt-2"></v-divider>
                                                        </template>
                                                    </v-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="['landing_pagev2'].includes(category)" class="rounded text-to-input">
                                            <div class="value-variable">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span  v-bind="attrs" v-on="on">
                                                            <v-icon class="mr-3" :color="variable.value !== null && variable.value !== '' ? (variable.inherit ? 'grey' : 'info') : 'error'">{{ variable.value !== null && variable.value !== '' ? '$map_marker_edit_s' : '$map_marker_time_s' }}</v-icon>
                                                        </span>
                                                    </template>
                                                    <span>{{ (variable.value !== null && variable.value !== "") ? (variable.inherit ? $t('variableHeritedTooltip') : $t('variableUpdatedTooltip')) : $t('variableNotSettedTooltip') }}</span>
                                                </v-tooltip>
                                                <div style="width: 100%;">
                                                    <v-select :items="landingV2Items" v-model="variable.value" @change="saveInputSelect(variable.value, category, variable.number, variable.type ? variable.type : null, variableName)" dense filled hide-details :no-data-text="$t('no-data-text')">
                                                    
                                                    </v-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="rounded text-to-input">
                                            <div class="value-variable">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span  v-bind="attrs" v-on="on">
                                                            <v-icon class="mr-3" :color="variable.value !== null && variable.value !== '' ? (variable.inherit ? 'grey' : 'info') : 'error'">{{ variable.value !== null && variable.value !== '' ? '$map_marker_edit_s' : '$map_marker_time_s' }}</v-icon>
                                                        </span>
                                                    </template>
                                                    <span>{{ (variable.value !== null && variable.value !== "") ? (variable.inherit ? $t('variableHeritedTooltip') : $t('variableUpdatedTooltip')) : $t('variableNotSettedTooltip') }}</span>
                                                </v-tooltip>
                                                <div style="width: 100%;">
                                                    <span v-html="variable.value"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td v-if="inCampaignTemplatePage">
                                        <v-btn v-if="variable.value !== null && !variable.inherit" text dense class="square" @click.stop="resetVar(variableName)">
                                            <v-icon small>$switch_r</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
               </div>
           </v-col>
       </v-row>
       <ConfirmDialogComponent ref="confirmDialog" />
       <!-- <FormDialogComponent v-if="form" ref="componentForm" :form="form" :action="'SET_DATA'" /> -->
    </div>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation';
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent';
//import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
//import getForm from "@/mixins/mixins";
import vCustomVariableTextField from '@/components/forms/inputs/v-custom-variable-text-field'
import GenericDataService from '@/services/GenericDataService';
export default {
    name:"templateDetailComponent",
    mixins: [dateManipulation],
    components:{ ConfirmDialogComponent, vCustomVariableTextField },
    props:['template', 'campaign', 'inCampaignTemplatePage', 'inTemplatePage', 'inWorkflow', 'inStepper', 'showEnvelopeTemplate', 'operationId', 'fromStepper'],
    data() {
        return {
            inputDisplayed: false,
            oldValue: "",
            form: null,
            displayVisioSelect: false,
            visioUnavailable: false,
            showCompiledTemplate: true,
            formUrl: "/operation/"+(this.$route.params.operation_id ? this.$route.params.operation_id : this.operationId)+"/getFormEntryPoints?"+(this.inStepper ? "stepper=true&" : "")+"type=",
            keyIdTpl: Math.random(999999999),
            disabledSaveButton: true,
            landingV2Items: [],
        }
    },
    mounted() {
        if(this.packId && this.packId == 2){
            GenericDataService.getData('/landingpagev2/selectList').then((response) => {
                this.landingV2Items = response.data.data;
            });
        }
    },
    computed: {
        packId(){
            if(this.$store.state.auth.currentUser.accountParameters.packId)
                return this.$store.state.auth.currentUser.accountParameters.packId
            else 
                return false
        },
        params(){
            let params = { id: this.template.id }
            if(this.$route.params.operation_id && this.$route.params.id){
                params.operation_id = this.$route.params.operation_id
                params.retroplanning_id = this.$route.params.id
            }
            return params
        },
        isLastVersion(){
            return (this.template.version < parseInt(this.lastVersion.version)) ? false : true
        },
        lastVersion(){
            if(this.template.versions && this.template.versions.length > 0)
                return this.template.versions.pop()

            return 1
        },
        createdAt: function(){
            return this.parseDatetimeStringToDatetime(new Date(this.template.updated_at));
        }, 
        notInTemplatesPage: function(){
            return this.$route.name !== 'Templates' && this.$route.name !== 'OutgoingCampaignList';
        },
        variablesByCategories: function(){
            let variables = {};
            
            if(this.template && this.template.variables && this.template.variables.values) {
                for(let variableName in this.template.variables.values) {
                    let categories = variableName.split('.');

                    if(categories[1] && ['places', 'entities', 'entrypoints'].includes(categories[1])) { // Gestion spéciale pour afficher des selects
                        if(!variables[categories[1]])
                            variables[categories[1]] = {};

                        if(['places', 'entities'].includes(categories[1])) {
                            if(categories[categories.length - 1] === 'id' || !this.inCampaignTemplatePage)
                                variables[categories[1]][categories[1] + '_' + categories[2]] = {value: this.template.variables.values[variableName].value ? this.template.variables.values[variableName].value.toString() : null, inherit: true, number: categories[2]};
                        } else {
                            if(categories[categories.length - 1] === 'id' || !this.inCampaignTemplatePage)
                                variables[categories[1]][categories[1] + '_' + categories[2] + '_' + categories[3]] = {value: this.template.variables.values[variableName].value ? this.template.variables.values[variableName].value.toString() : null, inherit: true, type: categories[2], number: categories[3]};
                        }
                    } else {
                        if(!variables[categories[0]])
                            variables[categories[0]] = {};
                        variables[categories[0]][variableName] = this.template.variables.values[variableName];
                    }
                }
            }

            if(variables.unsubscribe)
                delete variables.unsubscribe;

            return variables;
        },
        templateShown: function() {
            if(this.template && this.template.template) {
                if(this.showCompiledTemplate && this.template.template_compiled)
                    return this.template.template_compiled;
                else
                    return this.template.template;
            }
            
            return false;
        },
        paramsShown: function() {
            if(this.template && this.template.params) {
                if(this.showCompiledTemplate && this.template.params_compiled)
                    return this.template.params_compiled;
                else
                    return this.template.params;
            }
            
            return false;
        }
    },
    watch: {
       
    },
    methods: {
        stopPreview(){
            //audioPreview
            var player = document.getElementById('audioPreview');
            player.pause();
            player.src = player.src;
        },
       
        getFormToAdd(category, type) {
            let url = this.formUrl+type;
            this.$emit('addEntryPoint', url, type)
        },
        changeToLastVersion(){
            let lastVersionTemplateId = this.lastVersion.id;
            let configCampaign = this._.cloneDeep(this.campaign.config)
                configCampaign.template = lastVersionTemplateId 
            GenericDataService.postData('/retroplanning/set?id='+this.campaign.id, {'config': configCampaign}).then((response) => {
                setTimeout(() => {
                    this.$emit('refreshCampaign') 
                }, 100);
                
            })
        },
        resizeIframe(id) {
            var iframe = document.getElementById(id);
            iframe.style.width = "100%";
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
        },
        resetVar(variable) {
            let url = this.inStepper ? "/operation/" + (this.$route.params.operation_id ? this.$route.params.operation_id : this.operationId) + "/updateVar?opObject_id="+this.campaign.id : "/retroplanning/" + this.campaign.id + "/updateVar"
            let action = {
                "confirm": {
                    "label": "resetVarConfirmTitle",
                    "message": "resetVarConfirmMessage"
                },
                "url": url,
            };
            this.confirmElement(action, {"variables": {[variable]: null}, "template_id": this.template.id}, variable);
        },
        confirmElement(link, arrayIds, variable) {
            let options = {
                action: link.url,
                ids: arrayIds
            };
            this.openConfirmModal(link, options, variable);
        },
        async openConfirmModal(link, options, variable){
            let response = await this.$refs.confirmDialog.open(this.$t(link.confirm.label), this.$t(link.confirm.message), options);
            if(response) {
                this.template.variables.values[variable] = response.data.data.variables.values[variable];
                this.template.template_compiled = response.data.data.template_compiled;
                this.template.params_compiled = response.data.data.params_compiled;
                this.$emit('refreshTemplate')
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            }
        },
        showInput(variableName) {
            if(!this.inputDisplayed){
                this.inputDisplayed = variableName;
                this.oldValue = this.template.variables.values[this.inputDisplayed].value
                this.template.variables.values[this.inputDisplayed]["inherit"] = false;
            }
        }, 
        saveOtherInput(){
            if(this.inputDisplayed && !this.inputDisplayed.includes('sender')){
                if(this.template.variables.values[this.inputDisplayed].value != this.oldValue) {
                    let postData = {"variables":  {[this.inputDisplayed]: this.template.variables.values[this.inputDisplayed].value}, "template_id": this.template.id};
                    let url = this.inStepper ? "/operation/" + (this.$route.params.operation_id ? this.$route.params.operation_id : this.operationId) + "/updateVar?opObject_id="+this.campaign.id : "/retroplanning/" + this.campaign.id + "/updateVar"
                    GenericDataService.postData(url, postData).then((response) => {
                        this.template.template_compiled = response.data.data.template_compiled;
                        this.template.params_compiled = response.data.data.params_compiled;
                        //this.template.variables.values[this.inputDisplayed]["inherit"] = false;
                        this.inputDisplayed = false;
                        this.$emit('refreshTemplate')
                        this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                            this.$store.dispatch("REMOVE_SNACK")
                        });
                    });
                    this.template.variables.values[this.inputDisplayed]["inherit"] = false;
                    this.inputDisplayed = false;
                } 
            }
        },
        saveInput() {
            if(this.inputDisplayed){
                if(this.template.variables.values[this.inputDisplayed].value != this.oldValue) {
                    let postData = {"variables":  {[this.inputDisplayed]: this.template.variables.values[this.inputDisplayed].value}, "template_id": this.template.id};
                    let url = this.inStepper ? "/operation/" + (this.$route.params.operation_id ? this.$route.params.operation_id : this.operationId) + "/updateVar?opObject_id="+this.campaign.id : "/retroplanning/" + this.campaign.id + "/updateVar"
                    GenericDataService.postData(url, postData).then((response) => {
                        this.template.template_compiled = response.data.data.template_compiled;
                        this.template.params_compiled = response.data.data.params_compiled;
                        //this.template.variables.values[this.inputDisplayed]["inherit"] = false;
                        this.inputDisplayed = false;
                        this.$emit('refreshTemplate')
                        this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                            this.$store.dispatch("REMOVE_SNACK")
                        });
                    });
                    this.template.variables.values[this.inputDisplayed]["inherit"] = false;
                    this.inputDisplayed = false;
                } else {
                    this.inputDisplayed = false;
                }
            }
            
        },
        saveSenderMail(value){
            let varName = 'sender.sender'
            let postData = {"variables": {[varName]: value}, "template_id": this.template.id};
            let url = this.inStepper ? "/operation/" + (this.$route.params.operation_id ? this.$route.params.operation_id : this.operationId) + "/updateVar?opObject_id="+this.campaign.id : "/retroplanning/" + this.campaign.id + "/updateVar"
            GenericDataService.postData(url, postData).then((response) => {
                this.template.template_compiled = response.data.data.template_compiled;
                this.template.params_compiled = response.data.data.params_compiled;
                this.inputDisplayed = false;
                this.$emit('refreshTemplate')
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            });
        },
        saveInputSelect(value, category, number, type = null, variableName = null) {
            let varName;
            if(category != "landing_pagev2")
                varName = 'operation.' + category + '.' + (type ? (type + '.') : '') + number + '.id';
            else
                varName = variableName;
            let postData = {"variables": {[varName]: value}, "template_id": this.template.id};
            let url = this.inStepper ? "/operation/" + (this.$route.params.operation_id ? this.$route.params.operation_id : this.operationId) + "/updateVar?opObject_id="+this.campaign.id : "/retroplanning/" + this.campaign.id + "/updateVar"
            GenericDataService.postData(url, postData).then((response) => {
                this.template.template_compiled = response.data.data.template_compiled;
                this.template.params_compiled = response.data.data.params_compiled;
                this.inputDisplayed = false;
                this.$emit('refreshTemplate')
                this.$store.dispatch('ADD_SNACK', { message : this.$t('successActionConfirmed') }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            });
        },
        closeConditional (e) {
            return this.inputDisplayed;
        },
        showEnvelopeTemplateFunction() {
            this.$emit('showEnvelopeTemplateFunction', !this.showEnvelopeTemplate);
        }
    }
}
</script>

<style lang="scss">
    .template-detail {
        .v-data-table {
            background-color: transparent !important;
        }
        td {
            font-size: 13px !important;
            line-height: 18px !important;
            padding-top:20px !important;
            padding-bottom:20px !important;
        }
        tr {
            background-color: transparent !important;
        }
        tr:hover {
            background-color: transparent !important;
        }
        .heading, .heading:hover {
            background-color: var(--v-lightgrey-base) !important;
            th{
                height:40px !important;
                width:50% !important;
                
            }
        }
        .template-show{
            min-height: 70px;
            background-color: #fff;
            background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%), linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%);
            background-size: 15px 15px;
            background-position: 0 0, 7.5px 7.5px;
            background-repeat: repeat;
            position:relative;
            padding:15px;
            border:1px solid #eee;
            border-radius: 4px;
            .action-buttons {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
        .value-variable {
            width: 100%;
            display:flex;
            align-items: center;
        }
        .text-to-input {
            padding: 12px 16px;
            &.active {
                background-color: #F5F5F5;
                cursor: pointer;

            }
        }
    }
</style>
