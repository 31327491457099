var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col col-12 pa-0 input-form v-custom-combobox"},[(_vm.field.title)?_c('span',{staticClass:"label"},[_c('b',[_vm._v(_vm._s(_vm.$t(_vm.field.title)))]),(_vm.field.props && _vm.field.props.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" *")]):_vm._e(),(_vm.field.tooltip)?_c('infoTooltip',{attrs:{"tooltip":_vm.field.tooltip}}):_vm._e()],1):_vm._e(),(_vm.type === 'date')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"input-form",attrs:{"flat":"","solo":"","hide-details":"","dense":"","label":_vm.field.title ? _vm.$t(_vm.field.title) : '',"required":"","readonly":"","append-icon":"$calendar_alt_l"},on:{"focus":_vm.loadData},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-text-field',attrs,false),on))]}}],null,false,746997366),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1,"locale":_vm.$i18n.locale},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):(_vm.field.props.disable_typing)?_c('v-autocomplete',_vm._b({class:'input-form',attrs:{"label":_vm.field.title ? _vm.$t(_vm.field.title) : '',"items":this.items,"loading":_vm.loadingData,"rules":[_vm.validate],"type":_vm.type,"solo":"","flat":"","clearable":_vm.field.props.clearable == false ? false : true,"validate-on-blur":"","no-data-text":_vm.$t('no-data-text')},on:{"focus":_vm.loadData},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('span',[_vm._v(" "+_vm._s(data.item['to_trad'] ? _vm.$t(data.item.text) : data.item.text)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(item['to_trad'] ? _vm.$t(item.text) : item.text)+" ")])],1)],1)],1)]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-autocomplete',_vm.field.props,false)):_c('v-combobox',_vm._b({attrs:{"label":_vm.field.title ? _vm.$t(_vm.field.title) : '',"value":_vm.value,"items":this.items,"loading":_vm.loadingData,"solo":"","flat":"","rules":[_vm.validate],"type":_vm.type,"validate-on-blur":""},on:{"focus":_vm.loadData,"input":function($event){_vm.value = $event}},scopedSlots:_vm._u([(_vm.field.prepend_inner)?{key:"prepend-inner",fn:function(){return [_vm._v(" "+_vm._s(_vm.field.prepend_inner.to_trad ? _vm.$t(_vm.field.prepend_inner.text) : _vm.field.prepend_inner.text)+" ")]},proxy:true}:null],null,true)},'v-combobox',_vm.field.props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }