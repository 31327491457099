<template>
  <div class="datatable">
    <!-- ACTIONS GLOBALES -->
    <div class="d-flex justify-space-between align-center" v-if="(actions && actions.global) || limit">
        <v-alert border="left" text :color="limitPercent < 70 ? 'success' : (limitPercent < 90 ? 'warning' : 'error')" v-if="limit" :key="limit" width="40%">
            <v-icon class="mr-2" :color="limitPercent < 70 ? 'success' : (limitPercent < 90 ? 'warning' : 'error')">
                {{ limitPercent < 70 ? '$circleinfo_d' : (limitPercent < 90 ? '$warning_d' : '$delete') }}
            </v-icon>
            {{ limit - totalItems }} {{ $t(module+'available') }} ({{ $t('limitto') }}: {{ limit }}) 
        </v-alert>
        <div v-else></div>
        <div v-if="actions && actions.global" :class="'action-buttons mb-3' + (shiftButton ? ' mt-3 mr-3' : '')">
            <span v-for="(button, index) in actions.global.buttons" :key="'btn_'+index">
                <v-tooltip v-if="button._tooltip" top>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-btn :disabled="button._disabled" @click="actionForm(button)"  depressed style="margin-top:0 !important;" :class="(button._bg ? button._bg : 'bg-gradient') + ' ml-2'">
                                <v-icon left dark small v-if="button.icon"> ${{button.icon}} </v-icon>
                                <b>{{$t(button.title)}}</b>
                            </v-btn>
                        </span>
                    </template>
                    <span>{{button._tooltip}}</span>
                </v-tooltip>
                <v-btn v-else :disabled="button._disabled" @click="actionForm(button)"  depressed style="margin-top:0 !important;" :class="(button._bg ? button._bg : 'bg-gradient') + ' ml-2' + (flat ? ' btnFlat ma-0' : '')">
                    <v-icon left dark small v-if="button.icon"> ${{button.icon}} </v-icon>
                    <b>{{$t(button.title)}}</b>
                </v-btn>
            </span>
            <v-menu v-for="(menu, index) in actions.global.menus" :key="'menu_'+index" offset-y class="action-buttons ml-2">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed v-bind="attrs" v-on="on" ripple class="bg-gradient">
                        <v-icon left dark small v-if="menu.icon">
                            ${{menu.icon}}
                        </v-icon>
                        <b>{{$t(menu.title)}}</b>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(button, index) in menu.items" :key="'btnMenu_'+index" dense link @click.stop="actionForm(button)">
                        <v-list-item-title class="action-menu">
                            <v-icon small v-if="button.icon" class="mr-3">
                                ${{button.icon}}
                            </v-icon>
                            {{ $t(button.title) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu v-if="actions.global.multi && actions.global.multi.length > 0" offset-y left>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-3"
                        color="primary"

                        depressed
                        v-bind="attrs"
                        v-on="on"
                        :disabled="selected.length == 0"
                        ripple
                    >
                    <b>{{$t('multiActionsDatatableLabel')}}</b>
                    <v-icon  class="ml-3">
                        $expand
                    </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                    v-for="(action, index) in actions.global.multi"
                    :key="'multi_'+index"
                    dense
                    link
                    >
                        <v-list-item-title class="action-menu" @click.stop="action.action == 'form' ? actionForm(action, selected) : getLink('multi', action, selected)">
                            <v-icon small v-if="action.icon" class="mr-3">
                                ${{action.icon}}
                            </v-icon>
                            {{ $t(action.title) }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </div>
        <!-- /ACTIONS GLOBALES -->
    </div>
      <!-- Bandeau filtres -->
      <v-card-title class="pa-0 mb-3" v-if="((title && displayTitle) || (filters && filters.length > 0))">
          <v-expansion-panels :color="flat ? 'white' : 'lightgrey'" v-if="((title && displayTitle) || (filters && filters.length > 0) )" >
              <!-- Mise en read only et remise du curseur en normal lorsqu'il n'y a pas de filtres avancés -->
              <v-expansion-panel :readonly="!otherFilters || otherFilters.length == 0" :class="(!otherFilters || otherFilters.length == 0) ? 'no-filters no-shadow ' : 'no-shadow'">
                  <v-expansion-panel-header :color="flat ? 'white' : 'lightgrey'" class="pa-0"  @keyup.space.prevent="" disable-icon-rotate>
                      <span v-if="displayTitle"><b>{{$t(title)}}</b></span>
                      <!-- Filtres du header -->
                      <div class="heading-filters">
                          <div class="d-flex">
                            <div v-for="(filter, index) in headingFilters" :key="filter.name" :class="filter.type">
                                    <span @click.stop="" v-if="filter.type == 'input'">

                                        <!--v-text-field
                                                v-model="filter.value"
                                                append-icon="$magnify_l"
                                                @focus.stop=""
                                                @keydown="launchTextSearch"
                                                :label="$t(filter.title)"
                                                :placeholder="searchFilter"
                                                solo
                                                flat
                                                height="40"
                                                hide-details
                                                class="mr-0"
                                                :ref="filter.name"
                                        ></v-text-field-->
                                    </span>
                              <span v-else-if="filter.type=='radio'">
                                        <v-btn-toggle
                                                dense
                                                v-model="filter.value"
                                                color="white accent-3"
                                                background-color="primary"
                                                class="mr-3"
                                                @click.stop=""
                                                @change="filterResults"
                                        >
                                                <v-btn dense depressed color="primary" v-for="property in filter.properties"
                                                       :key="property.title"
                                                       @click.stop=""
                                                       :value="property.value">
                                                    {{$t(property.label)}}
                                                </v-btn>
                                        </v-btn-toggle>
                                    </span>
                              <span v-else-if="filter.type=='switch'">
                                        <div class="switch-filter mr-6" v-if="filter.properties.length == 2">
                                            <div>{{$t(filter.properties[0].label)}}</div>
                                            <v-switch
                                                    inset
                                                    v-model="filter.value"
                                                    hide-details
                                                    :false-value="filter.properties[0].value"
                                                    :true-value="filter.properties[1].value"
                                                    class="ml-3"
                                                    @click.stop=""
                                                    :label="$t(filter.properties[1].label)"
                                                    @change="filterResults"
                                            >
                                            </v-switch>
                                        </div>
                                        <div class="switch-filter" v-else-if="filter.properties.length == 1">
                                            <v-switch
                                                    inset
                                                    v-model="filter.value"
                                                    hide-details
                                                    dense
                                                    :false-value="false"
                                                    :true-value="filter.properties[0].value"
                                                    class="mr-2 pt-0"
                                                    :label="$t(filter.properties[0].label)"
                                                    @click.stop=""
                                                    @change="filterResults"
                                            >
                                            </v-switch>
                                        </div>
                                    </span>
                              <span v-else-if="filter.type == 'select'" :id="'headingFilters'+index"  class="headingFiltersSelect">
                                        <v-autocomplete
                                                @focus="getItems(filter)"
                                                v-model="filter.value"
                                                :items="getProperties(filter)"
                                                item-text="text"
                                                item-value="value"
                                                :label="$t(filter.title)"
                                                :ref="datatableId + filter.name"
                                                @change="filterResults(datatableId, filter)"
                                                :attach="'#headingFilters'+index"
                                                multiple
                                                clearable
                                                hide-details
                                                class="selectField mr-6 pt-0 mt-0"
                                                :no-data-text="$t('no-data-text')"
                                                :key="filter.name + '_' + filter.key"
                                        ></v-autocomplete>
                                    </span>
                              <span v-else-if="filter.type == 'daterange'" id="dateRangeFilter" class="dateRangeFilter">
                                <vDateRangePicker @change="filterResults" :attach="'#dateRangeFilter'" class="mr-6 " style="width:200px;" v-model="filter.value" />
                            </span>
                          </div>
                          </div>
                          <div v-for="filter in searchInput" :key="filter.name" :class="filter.type + (flat ? ' input-form': '')">
                              <v-text-field
                                      v-model="filter.value"
                                      @focus.stop=""
                                      @keyup.enter="filterResults"
                                      :label="$t(filter.title)"
                                      :placeholder="searchFilter"
                                      solo
                                      flat
                                        height="40"
                                      hide-details
                                      class="mr-0 searchInput"
                                      :ref="filter.name"
                              >
                                <template slot="append">
                                    <v-btn class="square" text @click="filterResults">
                                        <v-icon>$magnify_l</v-icon>
                                    </v-btn>
                                </template>
                            
                            </v-text-field>
                          </div>
                      </div>
                      <!-- Fin des filtres du header -->
                      <!-- Boutons d'actions pour afficher les filtres avancés et clear les filtres sélectionnés -->
                      <template v-slot:actions>
                          <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-btn min-width="39" height="39" @click="launchInputModification" v-bind="attrs" v-on="on" v-show="otherFilters && otherFilters.length > 0" small depressed color="primary" class="btn-icon">
                                      <v-icon small color="primarytext" dark>
                                          $filter_l
                                      </v-icon>
                                  </v-btn>
                              </template>
                              <span>{{$t("advancedFiltersDatatable")}}</span>
                          </v-tooltip>
                          <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-btn min-width="39" height="39" v-bind="attrs" v-on="on"  v-show="isFiltered" @click.stop="clearFilters" small depressed color="primary" class="btn-icon">
                                      <v-icon small color="primarytext" dark>
                                          $clear
                                      </v-icon>
                                  </v-btn>
                              </template>
                              <span>{{$t("clearFiltersDatatable")}}</span>
                          </v-tooltip>
                          <div v-if="columns && columns.hasOwnProperty('selected')"  class="icons-datatable" >
                            <v-tooltip v-if="setColumn" left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed text
                                        class="square ml-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click.prevent=""
                                        :ref="datatableId ? 'cogs'+datatableId : 'cogs'"
                                        :id="datatableId ? 'cogs'+datatableId : 'cogs'"
                                    >
                                    <v-icon color="primary" dark small> $cog_d </v-icon>
                                </v-btn>
                                </template>
                                <span>{{$t("columnChangeTooltip")}}</span>
                            </v-tooltip>
                            <DualListComponent v-if="columns && columns.hasOwnProperty('selected')" :ref="datatableId ? 'list_'+datatableId : 'list'" v-model="columns" :datatableId="datatableId" @changeColumns="changeColumns" @resetColumns="resetColumns"/>
                        </div>
                      </template>
                  </v-expansion-panel-header>
                  <!-- Contenu des filtres avancés -->
                  <v-expansion-panel-content class="pa-0">
                      <div v-for="filter in otherFilters" :key="filter.name" class="filter">
                                <span v-if="filter.type=='radio'">
                                    {{$t(filter.title)}}
                                    <v-btn-toggle
                                            v-model="filter.value"
                                            color="white accent-3 mr-3"
                                            background-color="primary"
                                            @change="filterResults"
                                    >
                                            <v-btn color="primary" @click.stop small v-for="property in filter.properties" :key="property.title" :value="property.value">{{$t(property.label)}}</v-btn>
                                    </v-btn-toggle>
                                </span>
                          <span v-else-if="filter.type == 'select'">
                                    <v-autocomplete
                                            @click="getItems(filter)"
                                            v-model="filter.value"
                                            :items="filter.properties"
                                            item-text="label"
                                            item-value="value"
                                            :label="$t(filter.title)"
                                            @change="filterResults"
                                            clearable
                                            outlined
                                            multiple
                                            hide-details
                                            dense
                                            :no-data-text="$t('no-data-text')"
                                    ></v-autocomplete>
                                </span>
                      </div>
                  </v-expansion-panel-content>
              </v-expansion-panel>
          </v-expansion-panels>
          
      </v-card-title>



    <v-card rounded="lg" :flat="flat" :class="flat ? 'no-shadow' : ''" :id="datatableId ? 'datatable'+datatableId : 'datatable'">
        <div v-if="barFilter" class="subSearch mt-1 pa-3 pt-6 px-6">
            <v-btn-toggle v-model="barFilter.value" @change="filterCounter" mandatory tile borderless background-color="white">
                <v-btn color="lightgrey" v-for="(btn,index) in counters" :value="btn.label" :active-class="'active-'+btn.color" :class="'border_'+btn.color" :key="'counter_'+index" class="btn-sub mx-2">
                    <b><span :class="btn.color+'--text mr-2 number'">{{btn.value}}</span> {{$t(btn.label)}}</b>
                </v-btn>
                <v-btn v-if="othercounter && othercounter.value" color="lightgrey" :value="othercounter.label" :class="'border_'+othercounter.color" :active-class="'active-'+othercounter.color" class="btn-sub mx-2 ml-4">
                    <b><span :class="othercounter.color+'--text mr-2 number'">{{othercounter.value}}</span> {{$t(othercounter.label)}}</b>
                </v-btn>
            </v-btn-toggle>

        </div>
      <v-data-table
        v-model="selected"
        :page.sync="page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :dense="dense"
        @click:row="clickedRow"
        @contextmenu:row="contextClick"
        :show-select="items.length > 0 && actions && actions.global && actions.global.multi &&  actions.global.multi.length > 0"
        ref="table"
        :no-data-text="$t('noDataTextDatatable')"
        :loading-text="$t('loadingData')"
        :footer-props="{
            'items-per-page-text': $t('objectPerPageDatatable'),
            'items-per-page-all-text': $t('allTextDatatable'),
            'items-per-page-options': [5, 10, 15, 20, 25, 30, 35, 40]

        }"
        multi-sort
        :disable-sort="forceDisableSort"
        :items-per-page="forceDisablePagination ? -1 : options.itemsPerPage"
        :hide-default-footer="forceDisablePagination"
        :show-expand="headersInExpand.length > 0 ? true : false"
        single-expand
        fixed-header
        :id="datatableId ? 'datatable'+datatableId : 'datatable'"
        style="overflow:hidden"
        :expanded.sync="expanded"
        :item-class="row_classes"
      >
        <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded }">
            <v-btn class="square" text @click.stop="expand(!isExpanded)">
                <v-icon color="primary" :class="isExpanded ? 'iconExpand rotate' : 'iconExpand'">$dropdown</v-icon>
            </v-btn>
        </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" v-if='headersInExpand.length > 0'>
                    <DatatableExpandComponent :headersInExpand="headersInExpand" :item="item" />
                </td>
            </template>
          <!-- Surcharge des checkbo pour sélectioné une page ou tte les valeurs -->
          <template v-slot:[`header.data-table-select`]="{}">
              <span class="selectAll" style="position:relative;" v-if="items.length > 0 ">
                  <v-checkbox
                    :value="propsSelect.value"
                    :indeterminate="propsSelect.indeterminate"
                    :ripple="false"
                    dense
                    class="pt-0 mt-0"
                    @click.prevent="clickSelectAll($event)"
                    ></v-checkbox>
                  <p class="ma-0 countSelectedBoxes" v-if="selectedNumber > 0">{{selectedNumber}}</p>
              </span>
          </template>
          <!-- Surcharge des autres header -->
          <template v-for="header in headers"  v-slot:[`header.${header.value}`]="{  }">
              <!-- Surcharge des actions en fin de ligne -->
              <div v-if="header.value === 'actions'"  class="" :key="header.value">
              </div>
              <div v-else-if="header.type == 'string_stat' || header.value=='ope_date' || header.value=='rdvs' || header.value=='passages'" class=" text-center" style="width:100%;" :key="header.value">
                {{$t(header.text)}}
              </div>
              <!-- Pour le reste des colonnes -->
              <div v-else :class="header.center ? 'text-center' : 'standardColHeader'" :key="header.value">{{$t(header.text)}}</div>
          </template>

          <!-- Surcharge de la colonne des boxes -->
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }" >
              <v-simple-checkbox class="selectRow"
                  :ripple="false" :value="isSelected" @input="select($event); changeBox($event)"
                  ></v-simple-checkbox>
          </template>


          <!-- Surcharge des différentes colonnes des lignes -->
          <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
              <!-- si on est dans la colonnes des actions -->
              <div class="icons-datatable" v-if="header.type == 'actions'" :key="header.value">
                  <span v-for="(link, idLink) in singleAction" :key="'linkcontainer_'+idLink">
                    <v-tooltip left v-if="displayLink(link, item)">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn depressed text class="square" v-bind="attrs" v-on="on" @click.stop="getLink('single', link, item)" :id="idLink" :key="'link_'+idLink">
                                <v-icon dark small :color="link.color ? link.color : 'primary'">
                                    ${{link.icon}}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{$t(link.title)}}</span>
                    </v-tooltip>
                  </span>

                  <v-menu offset-y left v-if="singleMenuAction.length > 0">
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" @click.stop=""  class="square float-right" depressed text>
                              <v-icon >$dots_l</v-icon>
                          </v-btn>
                      </template>
                      <v-list>
                          <v-list-item v-for="(link, idLink) in singleMenuAction" v-if="displayLink(link, item)" :key="'linkmenucontainer_' + idLink" @click.stop="getLink('single', link, item)" link dense>
                              <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
                          </v-list-item>
                      </v-list>
                  </v-menu>
              </div>
              <!-- si on est dans une colonne de type téléphones en array -->
              <div v-else-if="header.type == 'array_phone'" :key="header.value">
                  <div @click.stop="dialPhone(phone.number)" v-for="(phone, index) in item[header.value]" :key="'phone_'+index">
                      <v-chip color="primary" v-if="phone.type" x-small dark class="type-phone mr-2">{{phone.type}}</v-chip>
                      {{phoneNumberToClean(phone.number)}}
                  </div>
              </div>
              <!-- si on est dans une colonne de type entité -->
              <div v-else-if="header.type == 'identity'" :key="header.value">
                    <v-avatar size="36px" color="primary">
                        <v-img :src="api+'/user/'+item.id+'/getAvatar?token='+$store.state.auth.idToken+'&nocache=true'"></v-img>
                    </v-avatar>
                    <span class="ml-3">{{ item.firstname }} {{ item.name }}</span>
              </div>
              <!-- si on est dans une colonne de type téléphone -->
              <div v-else-if="header.type == 'phone'" :key="header.value">
                  <div @click.stop="dialPhone(item[header.value].number)">
                      <v-chip v-if="item[header.value].type" color="primary" x-small outlined class="type-phone mr-2">{{item[header.value].type}}</v-chip>
                      {{item[header.value].number}}
                  </div>
              </div>
              <!-- si on est dans une colonne de brand_array ou entity_array ou univers_array -->
              <div v-else-if="['brand_array', 'entity_array', 'univers_array'].includes(header.type)" :key="header.value">
                  <span v-for="(elem, index) in item[header.value]" :key="header.type+'_'+index">
                        <v-chip v-if="header.chips" :color="elem.color ? elem.color : 'primary'" small dark class="type-phone mr-2">
                            {{elem.label}}
                        </v-chip>
                        <span v-else>
                            {{elem.label ? elem.label : elem.text}}<span v-if="index < (item[header.value].length - 1)">, </span>
                        </span>
                  </span>
              </div>
              <!-- si on est dans une colonne de type Objet -->
              <div v-else-if="header.type == 'object' && item[header.value] && item[header.value].label" :key="header.value">
                    <v-chip v-if="header.chips" :color="item[header.value].color ? item[header.value].color : 'primary'" small dark class="type-phone mr-2">
                      {{item[header.value].label}}
                    </v-chip>
                  <span v-else>{{item[header.value].label}}</span>
                  <!-- <v-chip v-if="item[header.value].label" color="info" small outlined class="type-phone mr-2">
                      {{item[header.value].label}}
                  </v-chip> -->
              </div>
              <!-- si on est dans une colonne de type liens -->
                <div v-else-if="header.type == 'links'" :key="header.value">
                    <div v-for="(link, index) in item[header.value]" :key="'links_'+index">
                        <div v-if="link !== null">
                            <router-link :to="link" class="filterButton">
                                {{link.title}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- si on est dans une colonne de type lien -->
                <div v-else-if="header.type == 'link'" :key="header.value">
                    <div v-if="item[header.value] !== null" class="d-flex align-center">
                        <v-avatar size="40" v-if="header.value == 'user_link' && item[header.value].params && item[header.value].params.user_id" class="mr-2">
                            <v-img :src="(item[header.value].params.user_id >= 0 ? api+'/user/'+item[header.value].params.user_id+'/getAvatar?token='+$store.state.auth.idToken : api+'/user/getAvatar?id=-1&token='+$store.state.auth.idToken)"></v-img>
                        </v-avatar>
                        <div>
                            {{item[header.value].title}}
                        </div>
                    </div>
                </div>
                <div v-else-if="header.type == 'copyLink'" :key="header.value">
                    <div v-if="item[header.value] !== null" class="d-flex align-center">
                        <v-chip @click.stop="copyApiKey(item[header.value], item['id'])" outlined link dense>
                            {{item[header.value]}}
                        </v-chip>
                        <input type="hidden" :value="item[header.value]" :ref="'lplink_'+item['id']">
                    </div>
                </div>
                <div v-else-if="header.type == 'array_link'" :key="header.value">
                    <div v-if="item[header.value] !== null">
                        <div v-for="(linkArray, index) in item[header.value]" :key="'link_'+index">
                            <span  v-for="(link) in linkArray" :key="link.ref" >
                                <router-link v-if="!link.right || $func.hasRight(link.right)" :to="link" class="filterButton">
                                    {{link.title}}
                                </router-link>
                                <div v-else>
                                    {{link.title}}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else-if="header.type == 'object_link'" :key="header.value">
                    <div v-if="item[header.value] !== null">
                        <router-link v-if="!item[header.value].right || $func.hasRight(item[header.value].right)" :to="item[header.value]" class="filterButton">
                            {{item[header.value].title}}
                        </router-link> 
                        <div v-else>
                            {{item[header.value].title}}
                        </div>
                    </div>
                </div>
                <!-- si on est dans une colonne de type due -->
                <div v-else-if="header.type == 'due'" :key="header.value">
                    <v-chip :color="item[header.value].color" small >
                        <span v-if="item[header.value] != '' && item[header.value].value < 0">{{ $t('tolate') }}</span>
                        <span v-else-if="item[header.value] != '' && item[header.value].value <= 86400 && item[header.value].value >= 0">{{getHoursMinutesLeft(item[header.value].value)}}</span>
                        <span v-else-if="item[header.value] != '' && item[header.value].value > 86400">{{ getAfterOneDay(item[header.value].value) }}</span>
                        <span v-else>-</span>
                    </v-chip>
                </div>
                <!-- si on est dans une colonne de type chips -->
                <div v-else-if="header.type == 'chips' && (header.value =='status') && !['operationStatus', 'missionStatus'].includes(header.text)" :key="header.value">
                    <v-chip small dark :color="$func.getChipColorByStatus(item[header.value])">{{ $t(item[header.value]) }}</v-chip>
                </div>
                <div v-else-if="header.type == 'chips'" :key="header.value">
                    <span style="display:none;">{{item[header.value]}}</span>
                    <v-chip v-if="item[header.value] && !item[header.value].label && !item[header.value].name" color="info" small dark class="typesMenu-phone mr-2">
                        {{ header['to_trad'] ? $t(item[header.value]) : item[header.value] }}
                    </v-chip>
                    <v-chip v-else-if="item[header.value].label" :color="item[header.value].color ? item[header.value].color :'info'" small dark class="type-phone mr-2">
                        {{ header['to_trad'] ? $t((header.trad_prepend ? header.trad_prepend : '') + item[header.value].label) : item[header.value].label }}
                    </v-chip>
                    <v-chip v-else :color="item[header.value].color ? item[header.value].color :'info'" small dark class="type-phone mr-2">
                        <v-icon x-small class="mr-2">${{ item[header.value].icon }}</v-icon>
                        {{ header['to_trad'] ? $t(item[header.value].name) : item[header.value].name }}
                    </v-chip>
                </div>
                <div v-else-if="header.type == 'datetime'" :key="header.value">
                    <span v-if="(item[header.value]) && (item[header.value]) != 'null'">{{(parseFullDateToFr((item[header.value])))}}</span>
                </div>
                <div v-else-if="header.type == 'date' && item[header.value] != '-'" :key="header.value">
                    <span v-if="(item[header.value]) && (item[header.value]) != 'null'">{{(parseUsDateToFr((item[header.value].split(' ')[0])))}}</span>
                </div>
                <div v-else-if="header.type == 'chips_array'" :key="header.value">
                    <span v-for="(chip, index) in item[header.value]" :key="header.value+'_'+index">
                        <span style="display:none;">{{chip}}</span>
                        <v-chip v-if="chip" color="info" small outlined class="type-phone mr-2">
                            {{ header['to_trad'] ? $t(chip) : chip }}
                        </v-chip>
                    </span>
                </div>
                <div v-else-if="header.type == 'string_array'" :key="header.value">
                    <span v-for="(string, index) in item[header.value]" :key="header.value+'_'+index">
                        <span>{{string}}</span><span v-if="index < item[header.value].length - 1">, </span>
                    </span>
                </div>
                <div v-else-if="header.type == 'custom_status'" :key="header.value">
                    <span v-for="(status, index) in item[header.value]" :key="header.value+'_'+index">
                        <span v-if="status.from < parseFullJsDateToUs(new Date(), true) && status.to > parseFullJsDateToUs(new Date(), true)">
                            <span style="display:none;">{{status.status}} : {{status.detail}}</span>
                            <v-chip :color="status.color" small class="type-phone mr-2 white--text">
                                {{ $t(status.status) }} {{status.detail && status.detail != "" ? " :" : ""}} {{status.no_trad ? status.detail : $t(status.detail)}}
                            </v-chip>
                        </span>
                        
                    </span>
                    
                </div>
                <!-- "TO_CONTACT" => "warning",
            "LATE" => "error",
            "REPORTED" => "info",
            "ABANDONNED" => "black",
            "CONVERTED" => "success -->
                <div v-else-if="header.type == 'opportunity_status'" :key="header.value">
                    <v-chip class="white--text" small :color="item[header.value] == 'LATE' ? 'error' : (item[header.value] == 'TO_CONTACT' ? 'warning' : (item[header.value] == 'REPORTED' ? 'info' : (item[header.value] == 'ABANDONNED' ? 'black' : 'success')))">
                        {{ $t((item[header.value] == 'LATE') ? 'TO_CONTACT': item[header.value]) }} {{ item.detail && item.detail === 'external' ? (': ' + $t("opportunity_" + item.detail)) : '' }}
                    </v-chip>
                    <span v-for="(status, index) in item[header.value]" :key="'customstatus_'+index">
                        <span v-if="status.from < parseFullJsDateToUs(new Date(), true) && status.to > parseFullJsDateToUs(new Date(), true)">
                            <span style="display:none;">{{status.status}} : {{status.detail}}</span>
                            <v-chip :color="status.color" small class="type-phone mr-2 white--text">
                                {{ $t(status.status) }} {{status.detail && status.detail != "" ? ":" : ""}} {{status.no_trad ? status.detail : $t(status.detail)}}
                            </v-chip>
                        </span>
                        
                    </span>
                    
                </div>

                <div v-else-if="header.type == 'json_table'" :key="header.value">
                    <span v-if="!Array.isArray(item[header.value])">
                        <span style="display:none;">{{item[header.value]}}</span>
                        <v-chip v-if="item[header.value]" :color="color_status[item[header.value]]" small class="type-phone mr-2 white--text">
                            {{$t(item[header.value])}} <span v-if="item['detail_status']" style="white-space: pre-wrap;"> : {{ item[header.value] == 'FAIL' && item['detail_status'] != 'unknown' && !item['detail_status'].toLowerCase().includes('notcame') ? item['detail_status'] : $t(item['detail_status']) }} </span>
                        </v-chip>
                    </span>
                    <span v-else>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on"> 
                                    <div  v-if="_.keys(item[header.value]).length < 4" class="d-flex">
                                        <div v-for="(item, index) in item[header.value]" :key="'alert_'+index">
                                            <v-img width="20" height="20" class="mr-1" :src="item.url"></v-img>    
                                        </div>
                                    </div>
                                    <div v-else class="d-flex">
                                        <div v-for="index in 3" :key="'alert_'+index">
                                            <v-img width="20" height="20" class="mr-1" :src="_.values(item[header.value])[index].url"></v-img>
                                        </div>
                                        <div style="position:relative;">
                                            <span class="innerBadge">+{{ _.keys(item[header.value]).length - 3 }}</span>
                                            <v-icon class="badgeIcon" color="primary" style="height:20px !important; width:20px !important;">$circle_s</v-icon>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div>
                                <div v-for="(item, index) in item[header.value]" :key="'alerttooltip_'+index">
                                    - {{$t(item.desc)}}
                                </div>
                            </div>
                        </v-tooltip>
                    </span>
                </div>
                <!-- si on est dans une colonne de type json Recurrence -->
                <div v-else-if="header.type == 'recurrence'" :key="header.value">
                    <v-chip v-if="item[header.value]" color="info" small outlined class="type-phone mr-2">
                        <span v-if="(item[header.value]).day && !(item[header.value]).hasOwnProperty('month')">{{$t("AllOf")+" "+(item[header.value].day)+" "+$t('monthLabel')}}</span>
                        <span v-if="(item[header.value]).day && (item[header.value]).month">{{$t("AllOf")+" "+$t((item[header.value]).day)+" "+$t('ofLabel')+" "+$t((item[header.value]).month)}}</span>
                        <span v-if="(item[header.value]).dayOfWeek">{{$t("AllOf")+" "+$t((item[header.value]).dayOfWeek)}}</span>
                        <span v-if="(item[header.value]).date">{{(parseFullDateToFr((item[header.value]).date))}}</span>
                    </v-chip>
                </div>
                <!-- si on est dans une colonne de type chips -->
                <div v-else-if="header.type == 'object' && (header.value =='user_id' || header.value =='contact_id' || header.value =='contractor' || header.value =='assignment_user_id' || header.value =='created_by' || header.value =='validated_by' || header.value =='closed_by' || header.value =='imported_by')" class="d-flex align-center" :key="header.value">
                    <v-avatar size="40" v-if="(header.value == 'created_by' || header.value =='validated_by' || header.value =='imported_by' || header.value =='closed_by' || header.value =='contractor' || header.value =='assignment_user_id') && item[header.value] && item[header.value].id" class="mr-2">
                        <v-img :src="api+'/user/'+item[header.value].id+'/getAvatar?token='+$store.state.auth.idToken"></v-img>
                    </v-avatar>
                    <div>{{ (item[header.value] && item[header.value].firstname ? (item[header.value].firstname + " ") : '') + (item[header.value] && item[header.value].name ? item[header.value].name : '') }}</div>
                </div>
                <!-- si on est dans une colonne de type chips -->
                <div v-else-if="header.type == 'object' && (header.value =='affected_user') && item[header.value]" :key="header.value">
                    <div class="d-flex align-center" v-if="!item[header.value].is_presta">
                        <v-avatar size="40" v-if="(header.value =='affected_user') && item[header.value] && item[header.value].id" class="mr-2">
                            <v-img :src="api+'/user/'+item[header.value].id+'/getAvatar?token='+$store.state.auth.idToken"></v-img>
                        </v-avatar>
                        <div>{{ (item[header.value] && item[header.value].firstname ? (item[header.value].firstname + " ") : '') + (item[header.value] && item[header.value].name ? item[header.value].name : '') }}</div>
                    </div>
                    <span v-else>
                        {{ item[header.value].social_raison }}
                    </span>
                </div>
                <div v-else-if="header.type == 'object' && (header.value =='template_id')" :key="header.value">
                    <v-chip v-if="item[header.value].id" small outlined color="black">
                        <v-icon small class="mr-2">$paintroller_l</v-icon>
                        #T{{ item[header.value].num }} - {{ item[header.value].version }}
                    </v-chip>
                    <v-chip v-else small outlined color="warning">
                        <v-icon small class="mr-2">$paintroller_l</v-icon>
                        {{$t('tobedefined')}}
                    </v-chip>
                </div>
                <div v-else-if="header.type == 'object' && (header.value =='retroplanning_id')" :key="header.value">
                    {{ item[header.value].name ? item[header.value].name : "" }}
                </div>
                <div v-else-if="header.type == 'object' && (header.value =='status')" :key="header.value">
                    <v-chip small class="white--text" :color="$func.getChipColorByStatus(item[header.value])">{{ $t(item[header.value]) }}</v-chip>
                </div>
                <div v-else-if="header.type == 'boolean'" :key="header.value">
                   <v-icon dense v-if="item[header.value] == true" color="success" text>$check_l</v-icon>
                    <v-icon v-if="item[header.value] == false" dense color="error" text>$close</v-icon>
                </div>
                <div v-else-if="header.type == 'switch'" :key="header.value">
                    <v-btn depressed dense color="success lighten-5" class="square"  v-if="typeof(item[header.value]) == 'boolean' ? item[header.value] : parseInt(item[header.value])" @click.stop="switchData({active: false, arrayIds:[item[switchIds]], headerValue:header.value}, item)"><v-icon dense color="success" text>$check_l</v-icon></v-btn>
                    <v-btn depressed dense color="error lighten-5" class="square"  :disabled="item.hasOwnProperty('tobeconfig') && item.tobeconfig == '1'" v-if="typeof(item[header.value]) == 'boolean' ? !item[header.value] :  !parseInt(item[header.value])" @click.stop="switchData({active: true, arrayIds:[item[switchIds]], headerValue:header.value}, item)"><v-icon dense :color="(item.hasOwnProperty('tobeconfig') && item.tobeconfig == '1') ? 'grey' : 'error'" text>$close</v-icon></v-btn>
                </div>
                <div v-else-if="header.type == 'todoType'" :key="header.value" class="d-flex align-center">
                    <v-list-item-avatar class='d-flex justify-center' :color="item['icon_color']">
                        <v-icon small class="iconTodo" color="white">{{item['icon']}}</v-icon>
                    </v-list-item-avatar>
                    <div v-if="item.type !== 'REMIND'">{{ header['to_trad'] ? $t(item[header.value]) : item[header.value] }}</div>
                    <div v-else>{{ item.title }}</div>
                </div>
                <div v-else-if="header.type == 'label'" :key="header.value">{{ header['to_trad'] ? $t(item[header.value].label) : item[header.value].label }}</div>
              <!-- sinon -->
                <div v-else-if="header.type == 'string' && header.value == 'config.main_contact'" :key="header.value">
                    {{item[header.value] ? JSON.parse(item[header.value]).text : ''}}
                </div>
              <div v-else-if="header.type == 'string' && typeof(item[header.value]) == 'object'" :key="header.value">
                  <v-chip small outlined color="primary" class="mr-2" v-for="(value, index) in item[header.value]" :key="header.value+'_'+index">
                            {{$t(value)}}
                  </v-chip>
                </div>
                <div v-else-if="header.type == 'items'" :key="header.value">
                  <div v-for="(item, index) in JSON.parse(item[header.value])">{{item.data.label}}</div>
                </div>
                <div v-else-if="header.type == 'alert'" :key="header.value">
                    <div v-if="item[header.value]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on"> 
                                    <div  v-if="_.keys(item[header.value]).length < 4" class="d-flex align-center">
                                        <div v-for="(item, index) in item[header.value]" :key="'alert_'+index">
                                            <span v-if="item.tag === 'custom'" class="innerAlert primary bg--text mr-1">{{ item.data.name }}</span>
                                            <v-img v-else width="20" height="20" class="mr-1" :src="item.picto.url"></v-img>
                                        </div>
                                    </div>
                                    <div v-else class="d-flex align-center">
                                        <div v-for="index in 3" :key="'alert_'+index">
                                            <span v-if="_.values(item[header.value])[index].tag === 'custom'" class="innerAlert primary bg--text mr-1">{{ _.values(item[header.value])[index].data.name }}</span>
                                            <v-img v-else width="20" height="20" class="mr-1" :src="_.values(item[header.value])[index].picto.url"></v-img>
                                        </div>
                                        <span class="innerAlert pink white--text">+{{ _.keys(item[header.value]).length - 3 }}</span>
                                    </div>
                                </div>
                            </template>
                            <div>
                                <div v-for="(item, index) in item[header.value]" :key="'alerttooltip_'+index">
                                    - {{  item.tag !== 'custom' ? $t(item.picto.desc) : item.data.name }}
                                </div>
                            </div>
                        </v-tooltip>
                    </div>
                </div>
                <div v-else-if="header.type == 'alertV2'" :key="header.value">
                    <div v-if="item[header.value]">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on"> 
                                    <div  v-if="_.keys(item[header.value]).length < 4" class="d-flex align-center">
                                        <div v-for="(item, index) in item[header.value]" :key="'alert_'+index">
                                            <span v-if="item.custom" class="innerAlert primary bg--text mr-1">{{ item.name }}</span>
                                            <v-img v-else width="20" height="20" class="mr-1" :src="item.url"></v-img>
                                        </div>
                                    </div>
                                    <div v-else class="d-flex align-center">
                                        <div v-for="index in 3" :key="'alert_'+index">
                                            <span v-if="_.values(item[header.value])[index].custom" class="innerAlert primary bg--text mr-1">{{ _.values(item[header.value])[index].name }}</span>
                                            <v-img v-else width="20" height="20" class="mr-1" :src="_.values(item[header.value])[index].url"></v-img>
                                        </div>
                                        <span class="innerAlert pink white--text">+{{ _.keys(item[header.value]).length - 3 }}</span>
                                    </div>
                                </div>
                            </template>
                            <div>
                                <div v-for="(item, index) in item[header.value]" :key="'alerttooltip_'+index">
                                    - {{  !item.custom ? $t(item.desc) : item.name }}
                                </div>
                            </div>
                        </v-tooltip>
                    </div>
                </div>
                <div v-else-if="header.type == 'array' && header.value=='config.demand_type_generated'" :key="header.value">
                    <template v-if="item[header.value] && item[header.value].length > 0">
                        <span v-for="(item, index) in (item[header.value])" :key="'test_'+index">
                            {{$t(item)}}  
                        </span>
                    </template>
                </div>
                <div v-else-if="header.type == 'array'" :key="header.value">
                  <div v-for="(item, index) in (item[header.value])">{{item.reference}} : {{item.value}}</div>
                </div>
                <div v-else-if="header.type == 'json'  && (header.value =='avatar')" :key="header.value">
                    <v-avatar size="40" v-if="item.id" class="mr-2">
                        <v-img :src="api+'/user/'+item.id+'/getAvatar?token='+$store.state.auth.idToken"></v-img>
                    </v-avatar>
                </div>
                <div v-else-if="header.type == 'json'" :key="header.value">
                  <div v-for="(item, index) in (item[header.value])">{{item.config.name}}</div>
                </div>
                <div v-else-if="header.type == 'string' && (header.value =='demand_type')" :key="header.value">
                    <template v-if="item[header.value]">
                        <span v-for="(type, index) in item[header.value].split(',')">
                            {{ $t(type) }}{{ ((index < (item[header.value].split(',').length - 1)) ? ',' : '') }}
                        </span>
                    </template>
                </div>
                <div v-else-if="header.type == 'string' && (header.value =='due')" :key="header.value">
                    <span v-if="item[header.value] != '' && item[header.value] < 0">{{ $t('tolate') }}</span>
                    <span v-else-if="item[header.value] != '' && item[header.value] <= 86400 && item[header.value] >= 0">{{getHoursMinutesLeft(item[header.value])}}</span>
                    <span v-else-if="item[header.value] != '' && item[header.value] > 86400">{{ getAfterOneDay(item[header.value]) }}</span>
                    <span v-else>-</span>
                </div>
                <div v-else-if="header.type == 'string' && (header.center == true)" :key="header.value" class="text-center">
                  {{ header['to_trad'] ? $t(item[header.value]) : item[header.value] }}
                </div>
                <div v-else-if="header.type == 'rdv_string'" :key="header.value" class="text-center">
                    <v-menu
                        v-if="Object.keys(item[header.value]).length > 1"
                        offset-y
                        center
                        open-on-hover
                        :close-on-content-click="false"
                        content-class="no-shadow testMenu"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <div class="value" v-bind="attrs" v-on="on" >{{ item[header.value].global.value+"/"+((Object.values(item.objectives).length > 0) ? Object.values(item.objectives).map((e) => e.objective_nb).reduce((a, b) => (parseInt(a) + parseInt(b)), 0) : 0) }}</div>   
                        </template>
                        <v-card rounded="lg" class="no-shadow">
                            <v-card-text>
                                <template v-for="(substat, index) in item[header.value]">
                                    <div v-if="index != 'global'" class="mb-2">
                                        <div class="d-flex justify-space-between">
                                            <span class="substat">{{ $t(index) }} ({{ substat }})</span>
                                            <div class="substatPercent">
                                                {{ getPercentage(substat,  item[header.value].global.value) }}%
                                            </div>
                                        </div>
                                        <v-progress-linear
                                        color="info"
                                        rounded
                                        :value="getPercentage(substat,  item[header.value].global.value)"
                                        ></v-progress-linear>
                                    </div>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <div v-else  class="value">{{ item[header.value].global.value+"/"+(Object.values(item.objectives).length > 0 ? Object.values(item.objectives).map((e) => e.objective_nb).reduce((a, b) => (parseInt(a) + parseInt(b)), 0) : 0) }}</div>
                  
                </div>
                <div v-else-if="header.type == 'string_stat'" :key="header.value" class="text-center">
                    <v-menu
                        v-if="Object.keys(item[header.value]).length > 1"
                        offset-y
                        center
                        open-on-hover
                        :close-on-content-click="false"
                        content-class="no-shadow testMenu"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <div class="value" v-bind="attrs" v-on="on" >{{ item[header.value].global.value }}</div>   
                        </template>
                        <v-card rounded="lg" class="no-shadow">
                            <v-card-text>
                                <template v-for="(substat, index) in item[header.value]">
                                    <div v-if="index != 'global'" class="mb-2">
                                        <div class="d-flex justify-space-between">
                                            <span class="substat">{{ $t(index) }} ({{ substat }})</span>
                                            <div class="substatPercent">
                                                {{ getPercentage(substat,  item[header.value].global.value) }}%
                                            </div>
                                        </div>
                                        <v-progress-linear
                                        color="info"
                                        rounded
                                        :value="getPercentage(substat,  item[header.value].global.value)"
                                        ></v-progress-linear>
                                    </div>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <div v-else  class="value">{{ item[header.value].global.value }}</div>
                </div>
                <div v-else-if="header.type == 'array_obj'" style="max-width:180px;">
                    <v-menu
                        v-if="Object.keys(item[header.value]).length > 0"
                        offset-y
                        center
                        open-on-hover
                        :close-on-content-click="false"
                        content-class="no-shadow testMenu"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <div class="value d-flex align-center justify-space-between" v-bind="attrs" v-on="on" >
                                <div style="width:125px;">
                                    <v-progress-linear
                                        color="info"
                                        class="mr-2"
                                        rounded
                                        :value="getPercentage(item.rdvs.global.value,  Object.values(item[header.value]).map((e) => e.objective_nb).reduce((a, b) => a + b, 0))"
                                        ></v-progress-linear>
                                </div>
                                <span style="width:60px; text-align:center;">{{ getPercentage(item.rdvs.global.value,  Object.values(item[header.value]).map((e) => e.objective_nb).reduce((a, b) => a + b, 0)) }}%</span>
                            </div>   
                        </template>
                        <v-card rounded="lg" class="no-shadow">
                            <v-card-text>
                                <template v-for="(substat, index) in (item[header.value])">
                                    <div v-if="index != 'global'" class="mb-2">
                                        <div class="d-flex justify-space-between">
                                            <span class="substat">{{ substat.name }}</span>
                                            <div class="ml-2 substatPercent">
                                                {{ substat.label }}
                                            </div>
                                        </div>
                                        <v-progress-linear
                                        :color="substat.percent_color"
                                        rounded
                                        :value="substat.percent_total"
                                        ></v-progress-linear>
                                    </div>
                                </template>
                                <div v-if="(item.rdvs.global.value - Object.values(item[header.value]).map((e) => e.rdv_nb).reduce((a, b) => a + b, 0)) > 0">
                                    <b>{{ (item.rdvs.global.value - Object.values(item[header.value]).map((e) => e.rdv_nb).reduce((a, b) => a + b, 0))+" "+$t('outsideCamp') }} </b>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <v-menu
                        v-else-if="Object.keys(item[header.value]).length == 0 && item.rdvs.global.value > 0"
                        offset-y
                        center
                        open-on-hover
                        :close-on-content-click="false"
                        content-class="no-shadow testMenu"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <div class="value d-flex align-center justify-space-between" v-bind="attrs" v-on="on" >
                                <div style="width:125px;">
                                <v-progress-linear
                                    color="info"
                                    class="mr-2"
                                    rounded
                                    :value="0"
                                    ></v-progress-linear>
                                </div>
                                <span style="width:60px; text-align:center;">0%</span>
                            </div>   
                        </template>
                        <v-card rounded="lg" class="no-shadow">
                            <v-card-text>
                               <b> {{ item.rdvs.global.value+" "+$t('outsideCamp') }} </b>
                            </v-card-text>
                        </v-card>
                    </v-menu>
                    <div v-else class="value d-flex align-center justify-space-between">
                        <div style="width:125px;">
                        <v-progress-linear
                            color="info"
                            class="mr-2"
                            rounded
                            :value="0"
                            ></v-progress-linear>
                        </div>
                        <span style="width:60px; text-align:center;">0%</span>
                    </div>
                </div>
                <div v-else-if="header.type == 'html'" :key="header.value">
                  <span v-if="item[header.value]" v-html="item[header.value]"></span>
                </div>
                <div v-else :key="header.value">
                  <span v-if="item[header.value]">{{ header['to_trad'] ? $t(item[header.value]) : item[header.value] }}</span>
                </div>
            </template>
            <template v-slot:[`footer.page-text`]="props">
                {{props.pageStart}}-{{props.pageStop}} {{$t('ofTextDatatable')}} {{props.itemsLength}}
            </template>
             <!-- <template v-slot:footer="props">
                    {{props}}
                    <v-btn @click="goNext(props.props.pagination.pageCount)">next</v-btn>
            </template> -->
      </v-data-table>
      <v-card class="contextMenu" v-click-outside="closeContext" v-if="showContext" :style="styleContext">
        <v-card-text class="pa-1">
            <v-list dense class="pa-0">
                <v-list-item link @click="handleContext">
                    {{ $t('openinnewtab') }}
                </v-list-item>
            </v-list>
        </v-card-text>
      </v-card>
    </v-card>
    <FormDialogComponent
        v-if="form"
        ref="componentForm"
        :form="form"
        :action="'SET_DATA'"
        @refreshData="readDataFromAPI" />
    <ActionMenusDialogComponent v-if="showActionMenusDialog" :items="itemsActionMenusDialog" ref="actionMenus" @actionForm="actionForm" @cancel="showActionMenusDialog = false;" />
    <ConfirmDialogComponent ref="confirmDelete" />

    <v-dialog persistent width="400" v-model="showDownload">
        <v-card>
            <v-card-text class="pa-6">
                <h5 class="icon-title">
                {{$t('downloadReady')}}
                <v-btn @click="showDownload = false" text class="square">
                    <v-icon small>$close</v-icon>
                </v-btn>
                </h5>
                <div class="text-center mt-4 mb-2">
                    <a :href="downloadUrl" @click="showDownload = false" download class="exportBtn">{{$t('clickForDownload')}}</a>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import vDateRangePicker from '@/components/forms/inputs/v-daterange-picker'
import GenericDataService from '@/services/GenericDataService'
import DualListComponent from '@/components/DualListComponent'
import getChipsTime from '@/mixins/dateManipulation';
import parseJsDateToDatetimeSql from '@/mixins/dateManipulation';
import parseDatetimeStringToDatetime from '@/mixins/dateManipulation';
import dateManipulation from '@/mixins/dateManipulation'
import ConfirmDialogComponent from '@/components/dialogs/ConfirmDialogComponent'
import ActionMenusDialogComponent from '@/components/dialogs/ActionMenusDialogComponent'
import DatatableExpandComponent from '@/components/DatatableExpandComponent'
export default {
  name: "DatatableComponent",
  components: {DualListComponent, ConfirmDialogComponent, vDateRangePicker, ActionMenusDialogComponent, DatatableExpandComponent},
  props: ['fromPopup', 'flat', "displayTitle", "url", "switchUrl", "switchValue", "switchIds", "datatableId", "forceDisablePagination", "forceDisableSort", "otherData", "disableForm", "shiftButton", "prefilters", "disableSaveParams", "dense"],
  mixins:[getChipsTime, parseJsDateToDatetimeSql, parseDatetimeStringToDatetime, dateManipulation],
  data() {
    return {
        formUrl: "",
        form: null,
        page: 1,
        limit: false,
        limitPercent: false,
        module : false,
        totalItems: 0,
        numberOfPages: 0,
        loading: true,
        filters: [],
        options: {},
        headers: [],
        items: [],
        actions: {},
        columns:{},
        setColumn: true,
        title:'',
        propsSelect: {
            value:false,
            indeterminate:false,
        },
        selected: [],
        selectedNumber: 0,
        allSelected: false,
        selectedIds: [],
        isFiltered:false,
        saveFilter:false,
        parameterKey:"",
        tmpItem: {},
        api: process.env.VUE_APP_API_URL,
        showActionMenusDialog: false,
        itemsActionMenusDialog: [],
        innerWidth: 2650,
        showExpand: false,
        headersInExpand:[],
        expanded: [],
        counters: [],
        counterValue: "",
        isHandlingHeaders: false,
        color_status : {
            'FAIL': 'state_fail',
            'SUCCESS': 'state_success',
            'SUFFERING': 'state_suffer',
            'TO_DEAL': 'state_todo',
            'WAITING': 'state_before'
        },
        othercounter: {},
        ctrlKeyOn: false,
        top:0,
        left:0,
        showContext: false,
        dataRightClick: {},
        downloadUrl: "",
        showDownload: false
    };
  },
     created() {
         window.addEventListener("resize", this.handleDataHeaders);
         this.registerKeyHandler(true)
         if(this.$route.query.sendEvent && !this.fromPopup)
            this.$emit(this.$route.query.sendEvent, JSON.parse(this.$route.query.dataEvent))
        // Paging
        if (this.$route.query.page && !this.fromPopup) {
            this.options.page = parseInt(this.$route.query.page);
        }
        if (this.$route.query.itemsPerPage && !this.fromPopup) {
            this.options.itemsPerPage = parseInt(this.$route.query.itemsPerPage);
        }
        // Sorting
        if (this.$route.query.sortBy && !this.fromPopup) {
            this.options.sortBy = decodeURIComponent(this.$route.query.sortBy).split(',');
        }
        if (this.$route.query.sortDesc && !this.fromPopup) {
            this.options.sortDesc = decodeURIComponent(this.$route.query.sortDesc).split(',').map(s => s == "true");
        }
    },
    destroyed() {
        this.registerKeyHandler(false)
        window.removeEventListener("resize", this.handleDataHeaders);
    },
    beforeCreate() {
        this.$options.components.FormDialogComponent = require('./dialogs/FormDialogComponent.vue').default
    },
     mounted() {
        setTimeout(() => {
            this.setAutoCompleteFalse()
            // this.setCustomSort(),
        }, 500);
    },
    beforeDestroy() {
        this.saveUserFilter();
    },
  //this one will populate new data set when user changes current page.
  watch: {
    options: {
        handler(newVal, oldVal) {
            if(JSON.stringify(newVal) != JSON.stringify(oldVal)) {
                this.readDataFromAPI();
            }
        },
    },
    isHandlingHeaders(val){
    },
    innerWidth(val) {
        //this.handleDataHeaders(false)
        if(!this.isHandlingHeaders){
            setTimeout(() => {
                this.handleDataHeaders(false);
            }, 100);
        }
        
    }
  },
  methods: {
        copyApiKey(url, id){
            this.$refs['lplink_'+id][0].focus();
            document.execCommand('copy');
            navigator.clipboard.writeText(this.$refs['lplink_'+id][0].value)
            this.$store.dispatch('ADD_SNACK', { message : this.$t('lplinkCopiedSuccessfully') }).then((e) => { this.$store.dispatch("REMOVE_SNACK"); });
        },
        testOutside(){
            if (this.$refs[this.datatableId ? 'list_'+this.datatableId : 'list']) {
                this.$refs[this.datatableId ? 'list_'+this.datatableId : 'list'].setMenuValue();
            }
        },
        getPercentage(substat, global){
            if(global == 0)
                return 0;

            let calcul = ""+((substat * 100)/global);
            let percent =0;
            if(calcul.length > 3)
                percent = ((substat * 100)/global).toFixed(2);
            else
                percent = calcul
            
            return percent

        },
        registerKeyHandler(register) {
            if (register) {
                window.addEventListener('keydown', this.keyHandler)
                window.addEventListener('keyup', this.keyHandler)
            } else {
                window.removeEventListener('keydown', this.keyHandler)
                window.removeEventListener('keyup', this.keyHandler)
            }
        },
        keyHandler(event) {
            if (!event.key === 'Control' && !event.key === 'Shift' && !event.key === 'Meta') return false
            if ((event.key === 'Control' || event.key === 'Meta') && event.type === 'keydown') {
                this.ctrlKeyOn = true
            } else if ((event.key === 'Control' || event.key === 'Meta') && event.type === 'keyup') {
                this.ctrlKeyOn = false
            } 
        },
        //gestion responsive des colonnes 
        handleDataHeaders(checkInnerWidth = true){
            this.isHandlingHeaders = true
            if(window.innerWidth != this.innerWidth || !checkInnerWidth){
                this.innerWidth = window.innerWidth;
                if(this.$refs.table && this.$refs.table.$el.childNodes[0].getElementsByTagName('table')[0].offsetWidth > this.$refs.table.$el.childNodes[0].offsetWidth){
                        let headersClone = this._.cloneDeep(this.headers)
                        let headersToRemove = headersClone.find((e) => !e.keep_responsive);
                        if(headersToRemove){
                            headersToRemove.widthElement = this.$refs.table.$el.childNodes[0].getElementsByTagName('table')[0].offsetWidth;
                            this.headersInExpand.push(headersToRemove);          
                            this.headers = this.headers.filter((e) => e.text != headersToRemove.text);   
                            
                            
                            setTimeout(() => {
                                this.handleDataHeaders(checkInnerWidth);
                            }, 300);
                        }
                        
                }
                if(this.$refs.table && this.headersInExpand.length > 0 && this.headersInExpand[this.headersInExpand.length - 1].widthElement < this.$refs.table.$el.childNodes[0].offsetWidth) {
                        let headerToPutBack = this.headersInExpand.pop();
                        headerToPutBack.widthElement = null;
                        this.headers.push(headerToPutBack);
                        this.headers.sort((a, b) => a.key - b.key);
                        setTimeout(() => {
                            this.handleDataHeaders(checkInnerWidth);
                        }, 300);
                }
                
            }
            this.headersInExpand = this._.uniqWith(this.headersInExpand, this._.isEqual)
            this.isHandlingHeaders = false
        },
    //check si les actions sont visibles ou non
      displayLink: function(link, line) {
          if(link.display_if){
              if(line[link.display_if.field] == link.display_if.value){
                  return true
              } else {
                  return false
              }
          } else if(link.display_if_different){
                let compare = link.display_if_different.value ? link.display_if_different.value : this.$store.state.auth.user.id
              if(line[link.display_if_different.field] == compare){
                  return false
              } else {
                  return true
              }
          } else {
              return true
          }
      },
      //gestion des switch dans les datatables
      switchData: function(data, item){
         GenericDataService.postData(this.switchUrl, data).then((response) => {
             if(this.switchValue) {
                item[this.switchValue] = data.active
             } else {
                item[data.headerValue] = data.active
             }
         })
      },
      //gestion des changements de colonnes
      changeColumns: function(){
        let columnsToRecord = {
            fields: this.columns.selected
        }
        this.$store.dispatch('settings/SET_PARAMETERS_DATATABLE', { key:this.parameterKey+"fields", data:columnsToRecord }).then((e) => {
            this.columns = {}
            this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true }).then((e) => {
                this.readDataFromAPI()
            })
        })

    },
    //reset des colonnes
    resetColumns: function(){
        this.columns = {
            selected:[]
        }
        GenericDataService.postData('user/deleteParameters?id='+this.$store.state.auth.currentUser.profile.id+'&paramsKey='+this.parameterKey+"fields").then((e) => {
            this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true }).then((e) => {
                    this.readDataFromAPI()
            })
        })
    },
    // méthode pour lancer un appel
    dialPhone(number){
        if(number && number != ''){
            window.location = 'tel:'+number;
        }
    },
    saveUserFilter() {
        //si on est pas sur une route avec param id alors on enregistre les filtres
        if(this.saveFilter && !this.$router.currentRoute.params.id){
            let parameterToRecord = {};
            let payload = {}
            this.filters.forEach(filter => {
                if(filter.name != "search"){
                    payload[filter.name] = filter.value;
                }
            });
            parameterToRecord.filters = payload
            this.$store.dispatch('settings/SET_PARAMETERS_DATATABLE', { key: this.parameterKey+"filters", data:parameterToRecord }).then((e) => {
                this.$store.dispatch('auth/GET_CURRENT_USER', {}, { root: true })
            })
        }
    },
    //Reading data from API method.
    readDataFromAPI() {
        this.loading = true;
        this.headersInExpand = [];
        const { page, itemsPerPage } = this.options;
        //let pageNumber = page - 1;
        this.parameterKey = this.$router.currentRoute.matched[this.$router.currentRoute.matched.length - 1].path + "_table/"
        let payload = {
            filters:{}
        }
        if(this.otherData)
            payload.otherData = this.otherData;
        //Gestion des affichages de colonnes : quand pas encore renseigné (donc premier appel), on va check si il y en as dans le store de sauvegardé
        if(this.columns && this.columns.selected){
            payload.fields = this.columns.selected
        } else {
            if(this.$store.state.auth.currentUser.parameters[this.parameterKey+"fields"]) {
                payload.fields = this.$store.state.auth.currentUser.parameters[this.parameterKey+"fields"].fields
            }
        }
        //Gestion des affichages de filtres : quand pas encore renseigné (donc premier appel), on va check si il y en as dans le store de sauvegardé

        if(this.barFilter && this.barFilter.value == "RESULTS"){
            this.barFilter.value = ""
        }

        if(this.filters && this.filters.length > 0){
            this.filters.forEach(filter => {
                payload.filters[filter.name] = filter.value;
            });
        } else if (this.$route.query.datatableFilters && !this.disableSaveParams) {
            let filter = this.$route.query.datatableFilters.replace("%7B","{");
            filter = filter.replace("%7D","}");
            filter = filter.replace("%5B","[");
            filter = filter.replace("%5D","]");
            filter = filter.replace("%3A",":");
            payload.filters = JSON.parse(filter);//&datatableFilters=[\"alert\": {\"upsell\"}]
            // this.$router.replace({'query': null});
        } else if (this.prefilters){
            payload.filters = this.prefilters;
        } else {
            // TODO: reactiver les filtres sauvegardés
            // if(this.$store.state.auth.currentUser.parameters[this.parameterKey+"filters"]) {
            //     payload.filters = this.$store.state.auth.currentUser.parameters[this.parameterKey+"filters"].filters
            // }
        }
      //SORTING
        if(this.options.sortBy.length > 0){
            payload.sort = []
            this.options.sortBy.forEach((element, index) => {
                let sortItem = {}
                sortItem.key = element
                if(this.options.sortDesc[index] === true){
                    sortItem.order = "DESC"
                } else {
                    sortItem.order = "ASC"
                }
                payload.sort.push(sortItem)
            })
        }
        let url;
        if(this.url.includes("?")){
            url = process.env.VUE_APP_API_URL+this.url+"&pageLen="+itemsPerPage+"&pageNum="+(page - 1)
        } else {
            url = process.env.VUE_APP_API_URL+this.url+"?pageLen="+itemsPerPage+"&pageNum="+(page - 1)
        }
        
        // save state in url
        if (!this.disableSaveParams) {
            let datatableFilters = {};
            let params = [];
            Object.keys(payload.filters).map(key => {
                let filter = payload.filters[key];
                if (typeof filter !== 'undefined' && (filter.length > 0 || Number.isFinite(filter) || (key === 'period' && filter.end))) {
                    datatableFilters[key] = filter;
                }
            })
            if (Object.keys(datatableFilters).length)
                params['datatableFilters'] = JSON.stringify(datatableFilters)
            if (page != 1)
                params['page'] = page;
            if (itemsPerPage != 10)
                params['itemsPerPage'] = itemsPerPage;
            if (this.options.sortBy && this.options.sortBy.length)
                params['sortBy'] = this.options.sortBy;
            if (this.options.sortDesc != "false" && this.options.sortDesc.length)
                params['sortDesc'] = this.options.sortDesc;

            if (Object.keys(params).length) {
                this.$router.replace({query:params}).catch(err => {})
                history.replaceState({}, null, this.$route.path + '?' + Object.keys(params)
                .map(key => { return ( encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) ) })
                .join('&'))
            } else {
                this.$router.replace({}).catch(err => {})
                history.replaceState({}, null, this.$route.path);
            }
        }

        GenericDataService
            .postData(url + '&fromDatatable=1', payload)
            .then((response) => {
                //Then injecting the result to datatable parameters.
                this.loading = false;
                this.items = response.data.data.data;
                this.headers = response.data.data.headers;
                this.counters = response.data.data.counters;
                this.othercounter = response.data.data.othercounter
                this.headers.forEach((header, index) => {
                    header.key = index + 100
                })
                this.columns = response.data.data.columns
                this.title = response.data.data.title;
                this.actions = response.data.data.actions;
                this.filters = response.data.data.filters;
                if(response.data.data.sorting){
                    this.options.sortBy = []
                    this.options.sortDesc = []
                    response.data.data.sorting.forEach((sorting, index) => {
                        this.options.sortBy.push(sorting.key)
                        if(sorting.order == "DESC"){
                            this.options.sortDesc[index] = true
                        } else {
                            this.options.sortDesc[index] = false
                        }
                    });
                }
                this.totalItems = parseInt(response.data.data.totalItems);
                this.limit = response.data.data.limit;
                this.limitPercent = Math.round((this.totalItems * 100)/this.limit);
                this.module = response.data.data.module;
                this.numberOfPages = Math.ceil(response.data.data.totalItems / 10);
                this.isFiltered = this.checkPrefiltered()
                 if(!this.isHandlingHeaders){
                    setTimeout(() => {
                        this.handleDataHeaders(false);
                    }, 200);
                }
               
            });
    },
    //appels pour getter les ids pour les actions multiples
    getIds(){
        let payload = {filters:{}}
        if(this.columns && this.columns.selected){
            payload.fields = this.columns.selected
        }
        if(this.filters && this.filters.length > 0){
            this.filters.forEach(filter => {
                if(filter.value){
                    payload.filters[filter.name] = filter.value;
                }
            });
        }
        let url = process.env.VUE_APP_API_URL + this.url + (this.url.includes('?') ? '&' : '?') + 'idsOnly=1&useJsonReturn=1';
        GenericDataService
            .postData(url, payload)
            .then((response) => {
            //Then injecting the result to datatable parameters.
            this.selectedIds = response.data.data;
        });
    },
    // Au changement de la selection on gère le cas ou tout est selectionné : on reselectionne juste la page
    changeBox($event){
        if($event === false){
            if(this.selectedNumber == this.totalItems){
                this.propsSelect.indeterminate = true
                this.allSelected = false
                this.selectedNumber = this.options.itemsPerPage - 1
            } else {
                this.selectedNumber = this.selectedNumber - 1
            }
        }
    },
    // action au clic sur les box de sélections pour permettre la sélection d'une page ou de la totalité des datas
    clickSelectAll: function($event){
        if(this.propsSelect.value && !this.propsSelect.indeterminate){
            this.propsSelect.value = false
            this.selected = []
            this.selectedNumber = 0
            this.allSelected = false
        } else if(!this.propsSelect.value && !this.propsSelect.indeterminate){
            this.propsSelect.indeterminate = true
            this.allSelected = false
            this.selectedNumber = this.totalItems < 10 ? this.totalItems : 10
            this.$refs.table.toggleSelectAll($event)
        } else if(!this.propsSelect.value && this.propsSelect.indeterminate) {
            this.propsSelect.value = true,
            this.propsSelect.indeterminate = false
            this.allSelected = true
            this.getIds()
            this.selectedNumber = this.totalItems
            this.selected = this.items
        }
    },
    actionForm(item, arrayIds = false) {
        if(this.showActionMenusDialog) {
            this.itemsActionMenusDialog = [];
            this.showActionMenusDialog = false;
        }

        if(item.action == "get"){
            if(item.blank){
                let routeData = this.$router.resolve({path: item.route});
                window.open(routeData.href, '_blank');
            } else {
                this.$router.push({ path: item.route })
            }
        } else if(item.action == "event"){
            this.$emit(item.event, item.params)
        } else if(item.action == "actionMenus") {
            this.itemsActionMenusDialog = item.items;
            this.showActionMenusDialog = true;
        } else if(item.action == "download") {
            GenericDataService.postData(item.url).then((response) => {
                var encodedUri = encodeURI("data:text/csv;charset=utf-8," + response.data);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "contacts.csv");
                document.body.appendChild(link); // Required for FF

                link.click(); // This will download the data file named "contacts.csv".
            });
        } else if(item.action == "delete"){
            this.deleteElement(item, [])
        } else {
            if(!arrayIds){
                this.getForm(item.url, false, item)
            } else {
                let ids;
                if(this.selectedNumber > arrayIds.length){
                    ids= this.selectedIds
                } else {
                    ids = arrayIds.map((e) => e.id)
                }
                this.getForm(item.url, ids)
            }
        }
    },
    // recupere l'action a faire selon le type du link (single et globaux)
    getLink(type, link, item){
        if(type == 'single'){
            if(link.action == "get"){
                let newLink = link.route.replace('{{id}}', item.id)
                if(link.blank){
                    let routeData = this.$router.resolve({path: newLink});
                    window.open(routeData.href, '_blank');
                } else {
                    this.$router.push({ path: newLink })
                }
            } else if(link.action == "delete" || link.action == "reinitPass"){
                this.deleteElement(link, [item.id])
            } else if(link.action == "form") {
                let newFormUrl = link.url.replace('{{id}}', item.id)
                this.getForm(newFormUrl, [item.id], link)
            } else if(link.action == "ajax") {
                let newUrl = link.url.replace('{{id}}', item.id)
                GenericDataService.getData(newUrl).then((response) => {
                    this.readDataFromAPI();
                })
            } else if(link.action == "event") {
                if(link.returnObject){
                    this.$emit(link.event, item)
                } else {
                    this.$emit(link.event, item.id)
                }
            } else if(link.action == "openUrl") {
                let url = link.url.replace('{{id}}', item.id);
                url = url.replace('{{VUE_APP_API_URL}}', this.api);
                window.open(url, '_blank');
            } else if(link.action == "download") {
                let url = link.url.replace('{{id}}', item.id)
                GenericDataService.postData(url).then((response) => {
                    if(response.data.data.directDownload){
                        this.downloadUrl = this.api + response.data.data.directDownload;
                        this.showDownload = true;
                    }
                });
            }
        } else {
            let ids;
            if(this.selectedNumber > item.length){
                ids = this.selectedIds
            } else {
                ids = item.map((e) => e.id)
            }
            this.deleteElement(link, ids) // dans le cas multi, item est l'array d'ids
        }
    },

    //Action pour getter le form
    getForm(url, arrayIds = false, action = false) {
        let payload = {}
        if(arrayIds){
            payload.ids = arrayIds
        }
        this.formUrl = url
        this.$store
            .dispatch("forms/GET_FORM_DATA", {
                url: this.formUrl,
                payload: payload
            })
            .then((e) => {
            this.form = this.$store.state.forms.forms[e.reference];
            setTimeout(() => {
                this.displayForm(this.form.params.title, action, e.reference);
            }, 100);
            });

    },
    //effacement d'un ou plusieur objet
    deleteElement(link, arrayIds){
        if(link.confirm){
            if(link.confirm.checkurl){
                let linkToCheck = link.confirm.checkurl
                this.getForm(linkToCheck+"?id="+arrayIds, arrayIds, link)
                // GenericDataService.postData(linkToCheck, arrayIds).then((response) => {
                //     let checkData = response.data.data;
                //     let options = {
                //         needDoubleValidation: checkData.needDoubleValidation,
                //         confirmationImpacts: checkData.impacts,
                //         deletable: checkData.deletable,
                //         confirmationImpactTitle: this.$t(checkData.text),
                //         action: link.url,
                //         ids: arrayIds
                //     }
                //     if(typeof checkData.deletable !== 'undefined' && checkData.deletable === false) {
                //         options.action = "";
                //         options.ids = [];
                //         let tmpLink = JSON.parse(JSON.stringify(link));
                //         tmpLink.confirm.message = this.$t(checkData.text);
                //         this.openConfirmModal(tmpLink, options, arrayIds)
                //     } else {
                //         this.openConfirmModal(link, options, arrayIds)
                //     }
                // })
            } else {
                let options = {
                    action: link.url,
                    ids: arrayIds
                }
                this.openConfirmModal(link, options, arrayIds)
            }
        }
    },
    //ouverture de la modale de confirmation de delete
    async openConfirmModal(link, options){
        if(await this.$refs.confirmDelete.open(this.$t(link.confirm.label), this.$t(link.confirm.message), options)) {
            this.readDataFromAPI();
            if(options.action) {
                this.$store.dispatch('ADD_SNACK', {
                    message : this.$t('successActionConfirmed'),
                }).then((e) => {
                    this.$store.dispatch("REMOVE_SNACK")
                });
            }
        }
    },
    //Affichage du form
    async displayForm(title, action, reference) {
        await this.$refs.componentForm.open(this.$t(title)).then((response) => {
            if(response && response.data && response.data.success) {
                this.displayDatatableAlert(response);
                if (response.nextStep) {
                    this.$store.state.forms.forms[reference].schema.steps[Object.keys(this.$store.state.forms.forms[reference].schema.steps)[response.nextStep - 2]].is_validate = true;
                    this.$store.state.forms.forms[reference].schema.current_step = response.nextStep;
                    this.$store.state.forms.forms[reference].model.id = response.data.data.id;
                    this.displayForm(title, action, reference);
                } else if(action && action.redirect) {
                    if(action.redirect.params)
                        Object.keys(action.redirect.params).map((e) => action.redirect.params[e] = response.data.data[e]);
                    if(action.redirect.query)
                        Object.keys(action.redirect.query).map((e) => action.redirect.query[e] = response.data.data[action.redirect.query[e]]);
                    this.$router.push(action.redirect);
                } else if(action && action.redirectTo){
                    let routeName = action.redirectTo.name
                    let params = {}
                    params[action.redirectTo.param] = response.data.data.id
                    this.$router.push({name: routeName, params: params});
                }else if(action && action.event) {
                    if(action.event == "launchSegmentation"){
                        this.$emit(action.event, response.data.data)
                    } else {
                        this.$emit(action.event, response.data.data.id, response.data.data)
                    }
                } else {
                    this.readDataFromAPI();
                }
            }
        })
    },
    displayDatatableAlert(response) {
        let message = this.$t('successActionConfirmed');

        if(response.data && response.data.data && response.data.data.snack)
            message = this.$tc(response.data.data.snack.message, response.data.data.snack.count, { count: response.data.data.snack.count });

        this.$store.dispatch('ADD_SNACK', {
            message : message,
        }).then((e) => {
            this.$store.dispatch("REMOVE_SNACK")
        })
    },
    closeContext: function(){
        this.top = 0,
        this.left = 0
        this.showContext = false;
    },
    handleContext: function(){
        let data = this.dataRightClick
        switch (this.defaultAction.action) {
                case 'get':
                    let link = this.globalLink(data.item)
                    let routeData = this.$router.resolve({path: link});
                    window.open(routeData.href, '_blank');
                    break;
                case 'link':
                    let linkObjectLink = data.item[this.defaultAction.field]
                    let routeDataLink = this.$router.resolve(linkObjectLink);
                    window.open(routeDataLink.href, '_blank');
                    break;
                case 'link_hydrated':
                    let linkObject = data.item[this.defaultAction.field]
                    if(linkObject.contact) {
                        let routeDataHydrat = this.$router.resolve(linkObject.contact[0]);
                    } else if(linkObject.demand){
                        let routeDataHydrat = this.$router.resolve(linkObject.demand[0]);
                    } else if(linkObject.operation){
                        let routeDataHydrat = this.$router.resolve(linkObject.operation[0]);
                    } else if(linkObject.rdv){
                        let routeDataHydrat = this.$router.resolve(linkObject.rdv[0]);
                    }  else if(linkObject.retroplanning){
                        let routeDataHydrat = this.$router.resolve(linkObject.retroplanning[0]);
                    } else {
                        let routeDataHydrat = this.$router.resolve(linkObject);
                    }
                    window.open(routeDataHydrat.href, '_blank');
                    break;
                case 'event':
                    const params = this.$route.query
                    params.sendEvent = this.defaultAction.event
                    params.dataEvent = JSON.stringify(data.item)
                    let routeEvent = this.$router.resolve({ name : this.$route.name, query:params})
                    //this.$route.query = {sendEvent: this.defaultAction.event}
                    window.open(routeEvent.href, '_blank');
                    break;
            }
    },
    contextClick:function($evt, data){
        if(this.defaultAction && this.defaultAction.action != 'form') {
            $evt.preventDefault();
            this.top = $evt.clientY - 5;
            this.left = $evt.clientX - 5;
            this.showContext = true;
            this.dataRightClick = data;
            
        }
        
    },
    //action au clic de la ligne
    clickedRow: function($event, data){
        if(this.actions && this.actions.single) {
            let linksColumn = this.actions.single.find((e) => e.default);
            if(linksColumn && linksColumn.action == "get"){
                let link = this.globalLink(data.item)
                this.saveUserFilter();
                if(linksColumn.blank || this.ctrlKeyOn){
                    let routeData = this.$router.resolve({path: link});
                    window.open(routeData.href, '_blank');
                } else {
                    this.$router.push({ path: link })
                }

            } else if (linksColumn && linksColumn.action == "getLink") {
                if(linksColumn.blank || this.ctrlKeyOn){
                    let routeData = this.$router.resolve(linksColumn);
                    window.open(routeData.href, '_blank');
                } else {
                    this.$router.push(linksColumn)
                }

            }else if(linksColumn && linksColumn.action == "form") {
                let newFormUrl = linksColumn.url.replace('{{id}}', data.item.id)
                this.getForm(newFormUrl, [data.item.id], linksColumn)
            } else if (linksColumn && linksColumn.action == "link"){
                let linkObject = data.item[linksColumn.field]
                if(linkObject && linkObject.name){
                    if(this.ctrlKeyOn){
                        let routeData = this.$router.resolve(linkObject);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(linkObject)
                    }
                }
            } else if(linksColumn && linksColumn.action == "event"){
                let object = data.item
                this.$emit(linksColumn.event, object, this.actions)

            } else if (linksColumn && linksColumn.action == "link_hydrated"){
                let linkObject = data.item[linksColumn.field]
                if(linkObject.contact){
                    if(this.ctrlKeyOn){
                        let routeData = this.$router.resolve(linkObject.contact[0]);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(linkObject.contact[0])
                    }
                } else if (linkObject.demand){
                    if(this.ctrlKeyOn){
                        let routeData = this.$router.resolve(linkObject.demand[0]);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(linkObject.demand[0])
                    }
                } else if (linkObject.operation){
                    if(this.ctrlKeyOn){
                        let routeData = this.$router.resolve(linkObject.operation[0]);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(linkObject.operation[0])
                    }
                }  else if (linkObject.rdv){
                    if(this.ctrlKeyOn){
                        let routeData = this.$router.resolve(linkObject.rdv[0]);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(linkObject.rdv[0])
                    }
                }   else if (linkObject.retroplanning){
                    if(this.ctrlKeyOn){
                        let routeData = this.$router.resolve(linkObject.retroplanning[0]);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(linkObject.retroplanning[0])
                    }
                } else {
                    if(this.ctrlKeyOn){
                        let routeData = this.$router.resolve(linkObject);
                        window.open(routeData.href, '_blank');
                    } else {
                        this.$router.push(linkObject)
                    }
                }
            }
        }
    },
    //création du lien global (pour le clic sur une ligne)
    globalLink: function(item){
        let linksColumn = this.actions.single.find((e) => e.default);
        let defaultLink = linksColumn.route
        this.tmpItem = item;
        if(defaultLink){
            let newLink = defaultLink.replace(/{{(.*?)}}/g, this.replaceFilter);
            return newLink
        } else {
            return item
        }
        
    },
    // Permet de remplacer les filtres dans l'URL par les valeurs de l'item
    replaceFilter(match, fieldName) {
        if(this.tmpItem && this.tmpItem[fieldName]) {
            if(typeof this.tmpItem[fieldName] === "object" && this.tmpItem[fieldName].id) {
                return this.tmpItem[fieldName].id;
            }

            return this.tmpItem[fieldName];
        }

        return '0';
    },
    //Méthodes pour clear les filtres
    clearFilters() {
        this.filters.forEach(filter => {
            if(filter.type == "input") {
                filter.value = ""
            } else if(filter.type == 'radio') {
                filter.value = true;
            }  else if(filter.type == 'switch') {
                filter.value = false;
            } else {
                filter.value = [];
            }
        });
        this.isFiltered = false
        this.filterResults();
    },
    filterCounter(){
        if(this.barFilter && this.barFilter.value == "RESULTS"){
            this.barFilter.value = ""
        }
        this.filterResults()
    },
    //Filtrer les résultats
    filterResults(datatableId, filter){
        this.saveFilter = true;
        // Permet de blur un field pour éviter le bug du no data available quand il n'y a plus de value
        if(filter && filter.value && Array.isArray(filter.value) && filter.value.length == 0 && this.$refs[datatableId + filter.name] && this.$refs[datatableId + filter.name][0]) {
            this.$refs[datatableId + filter.name][0].blur();
            filter.key++;
        }
        this.options.page = 1
        this.readDataFromAPI()
    },
    //Méthode de recherche textuelle
    launchTextSearch() {
        if(this.timeout != ""){
            clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
            this.filterResults()
        }, 500);
    },
    //Fonctions utilitaires pour mettre des autocomplete off sur les inputs
    launchInputModification: function(){
        setTimeout(() => {
            this.setAutoCompleteFalse();
        }, 200);
    },
    setAutoCompleteFalse: function (){
        let inputs = document.getElementsByTagName('input')
        inputs.forEach((input) => {
            input.setAttribute('autocomplete', 'nope');
        })
    },
    // Méthode de get des objets pour les select en ajax
    getItems(filter){
        if(filter.method =="ajax"){
            GenericDataService.getData(filter.query).then((response) => {
                filter.properties = response.data.data
            })
        } else if(filter.selectDataUrl){

            let url = filter.selectDataUrl;
            if(url.includes('{{entity_id}}')){
                url = url.replace('{{entity_id}}', this.$store.state.auth.currentUser.profile.entity_id)
            }

            GenericDataService.getData(url).then((response) => {
                filter.properties = response.data.data;
                filter.alreadyTrad = false;
                this.$forceUpdate()
            })

        }
    },
    getProperties(filter) {
        if(filter.to_trad && !filter.alreadyTrad) {
            for(var i = 0; i < filter.properties.length; i++) {
                
                if(typeof filter.properties[i] === 'object') {
                    filter.properties[i].text = this.$t(filter.properties[i].text);
                } else {
                    filter.properties[i] = {
                        'value': filter.properties[i],
                        'text': this.$t(filter.properties[i]),
                    }
                }
            }
            
        } else {
            let newProperties = [];
            if(filter.properties && filter.properties.length > 0){
                filter.properties.forEach(element => {
                    if(typeof element === 'object') {
                        if(element.to_trad){
                            newProperties.push({
                                'value': element.text,
                                'text': this.$t(element.text),
                            });
                        } else {
                            newProperties.push({
                                'value': element,
                                'text': element,
                            });
                        }
                    } else {
                        newProperties.push({
                            'value': element,
                            'text': element,
                        });
                    }
                });
            }
           
        }
        filter.alreadyTrad = true;
        return filter.properties;
    },
    checkPrefiltered() {
        if(!this.filters || !this.filters.filter)
            return false;
        let filters = this.filters.filter((e) => e.value && e.value != "" && e.value.length > 0 && e.type != 'switch')
        if(filters.length > 0){
            return true
        } else {
            return false
        }
    },
    row_classes(item) {
        if(item._main_contact) {
            return "mainContact"
        }
    }
  },
  computed: {
        // optionsEncode:function(){
        //     return JSON.stringify(this.options);
        // },
        defaultAction: function(){
            if(this.actions && this.actions.single && this.actions.single.length > 0)
                return this.actions.single.find((e) => e.default)
        },
        singleAction:function(){
            if(this.actions && this.actions.single && this.actions.single.length > 0)
                return this.actions.single.filter((e) => !e.default && !e.menu)
        },
        singleMenuAction:function(){
            if(this.actions && this.actions.single && this.actions.single.length > 0)
                return this.actions.single.filter((e) => e.menu)
            return [];
        },
        searchFilter: function(){
            if(this.filters && this.filters.length > 0){
                var filter = this.filters.find((e) => e.name == 'search')
                var fieldsArray = [];
                if(filter.field){
                    for (let index = 0; index < filter.field.length; index++) {
                        const element = filter.field[index];
                        fieldsArray.push(this.$t(element))
                    }
                    return this.$t('searchIn')+" : "+fieldsArray.join(', ')
                }
            }
        },
        // computed pour séparer les filtres du header et les filtres avancés
        headingFilters: function(){
            if(this.filters && this.filters.length > 0){
                return this.filters.filter((e) => e.header && e.type != 'input' && e.type != "bar_filter").map((e) => {
                    e.key = 0;
                    return e
                });
            }
        },
        barFilter: function(){
            if(this.filters && this.filters.length > 0){
                let barFilter = this.filters.find((e) => e.type == "bar_filter")
                if(barFilter && (barFilter.value == "" || !barFilter.value)){
                    barFilter.value = "RESULTS"
                }
                return barFilter
            }
        },
        searchInput: function(){
            if(this.filters && this.filters.length > 0){
                return this.filters.filter((e) => e.header && e.type == 'input')
            }
        },
        otherFilters: function(){
            if(this.filters && this.filters.length > 0){
                return this.filters.filter((e) => !e.header)
            }
        },
        styleContext: function() {
            return "top:"+this.top+"px; left:"+this.left+"px;"
        }
  },
};
</script>

<style lang="scss">
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
    &.border_pink {
        border-color: var(--v-error-base) !important;
        border-left: 5px solid var(--v-pink-base) !important;
    }
    &.border_purple2 {
        border-color: var(--v-purple2-base) !important;
        border-left: 5px solid var(--v-purple2-base) !important;
    }
    &.border_orange {
        border-color: var(--v-orange-base) !important;
        border-left: 5px solid var(--v-orange-base) !important;
    }
    &.border_cyan {
        border-color: var(--v-cyan-base) !important;
        border-left: 5px solid var(--v-cyan-base) !important;
    }
    &.border_state_before {
        border-color: var(--v-primary-base) !important;
        border-left: 5px solid var(--v-primary-base) !important;
    }
    &.border_black, &.border_state_fail {
        border-color: #000 !important;
        border-left: 5px solid #000 !important;
    }
    &.border_state_suffer {
        border-color: var(--v-error-base) !important;
        border-left: 5px solid var(--v-error-base) !important;
    }
    &.border_state_todo {
        border-color: var(--v-warning-base) !important;
        border-left: 5px solid var(--v-warning-base) !important;
    }
    &.border_state_success {
        border-color: var(--v-success-base) !important;
        border-left: 5px solid var(--v-success-base) !important;
    }
    &.border_success {
        border-color: var(--v-success-base) !important;
        border-left: 5px solid var(--v-success-base) !important;
    }
    &.border_info {
        border-color: var(--v-info-base) !important;
        border-left: 5px solid var(--v-info-base) !important;
    }
    &.border_error {
        border-color: var(--v-error-base) !important;
        border-left: 5px solid var(--v-error-base) !important;
    }
    &.border_primary {
        border-color: var(--v-primary-base) !important;
        border-left: 5px solid var(--v-primary-base) !important;
    }
    &.border_purple {
        border-color: var(--v-purple-base) !important;
        border-left: 5px solid var(--v-purple-base) !important;
    }
    &.border_warning {
        border-color: var(--v-warning-base) !important;
        border-left: 5px solid var(--v-warning-base) !important;
    }
    &.border_green {
        border-color: #5fcbad !important;
        border-left: 5px solid #5fcbad !important;
    }
    &.border_brown {
        border-color: #882a54 !important;
        border-left: 5px solid #882a54 !important;
    }

}
    .subSearch {
        width:100%;
        .v-btn-toggle {
            width:100%;
            .v-btn {
                max-width:100%;
                flex-grow:1;
                text-transform: unset !important;
                b {
                    display:flex;
                    align-items: center;
                }
                .number {
                    font-size:18px !important;
                }
                &.active-green {
                    background-color: #5fcbad !important;
                    color: white !important;
                    .green--text {
                        color: white !important;
                    }
                }
                &.active-brown {
                    background-color: #882a54 !important;
                    color: white !important;
                    .brown--text {
                        color: white !important;
                    }
                }
                &.active-state_before {
                    background-color: var(--v-primary-base) !important;
                    color: white !important;
                    .state_before--text {
                        color: white !important;
                    }
                }
                &.active-black, &.active-state_fail {
                    background-color: #000 !important;
                    color: white !important;
                    .black--text, .state_fail--text {
                        color: white !important;
                    }
                }
                &.active-state_suffer {
                    background-color: var(--v-error-base) !important;
                    color: white !important;
                    .state_suffer--text {
                        color: white !important;
                    }
                }
                &.active-state_todo {
                    background-color: var(--v-warning-base) !important;
                    color: white !important;
                    .state_todo--text {
                        color: white !important;
                    }
                }
                &.active-state_success {
                    background-color: var(--v-success-base) !important;
                    color: white !important;
                    .state_success--text {
                        color: white !important;
                    }
                }
                &.active-success {
                    background-color: var(--v-success-base) !important;
                    color: white !important;
                    .success--text {
                        color: white !important;
                    }
                }
                &.active-info {
                    background-color: var(--v-info-base) !important;
                    color: white !important;
                    .info--text {
                        color: white !important;
                    }
                }
                &.active-error {
                    background-color: var(--v-error-base) !important;
                    color: white !important;
                    .error--text {
                        color: white !important;
                    }
                }
                &.active-primary {
                    background-color: var(--v-primary-base) !important;
                    color: white !important;
                    .primary--text {
                        color: white !important;
                    }
                }
                &.active-purple {
                    background-color: var(--v-purple-base) !important;
                    color: white !important;
                    .purple--text {
                        color: white !important;
                    }
                }
                &.active-warning {
                    background-color: var(--v-warning-base) !important;
                    color: white !important;
                    .warning--text {
                        color: white !important;
                    }
                }

                &.active-pink {
                    background-color: var(--v-pink-base) !important;
                    color: white !important;
                    .pink--text {
                        color: white !important;
                    }
                }
                &.active-purple2 {
                    background-color: var(--v-purple2-base) !important;
                    color: white !important;
                    .purple2--text {
                        color: white !important;
                    }
                }
                &.active-orange {
                    background-color: var(--v-orange-base) !important;
                    color: white !important;
                    .orange--text {
                        color: white !important;
                    }
                }
                &.active-cyan {
                    background-color: var(--v-cyan-base) !important;
                    color: white !important;
                    .cyan--text {
                        color: white !important;
                    }
                }
                &::before {
                    opacity:0 !important;
                }
            }
        }
    }
    .datatable {
        .iconExpand {
            transition: transform 0.1s ease-in;
            &.rotate{
                transform:rotate(180deg);
            }
        }
        th {
            z-index:0 !important;
           
        }
        //  th[aria-label="actions"] {
        //         z-index:2 !important;
        //     }
        tr.v-data-table__expanded__content {
            box-shadow: none !important;
            background-color: var(--v-lightgrey-base) !important;
        }
        .headerTest  {
            white-space: nowrap;
            .standardColHeader {
                display:inline;
                margin-right:5px;
            }
        }
        .innerBadge {
            position:absolute;
            color: white;
            z-index:2;
            font-size:12px;
            top:1px;
            left:1px;
        }
        .badgeIcon {
            .v-icon__component {
                width:20px !important;
                height:20px !important;
            }
        }
        .v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus::before {
            opacity:0 !important;
        }
        .switch-filter {
            display:flex;
            align-items: center;
        }
        .selectRow {
            height:100% !important;
            display: flex;
            align-items: center;
        }
        .action-multi {
            cursor:pointer;
        }
        .v-data-table > .v-data-table__wrapper > table > thead > tr > th{
            font-size:15px;
            height:60px;
            font-weight:900;
            color:#000000;
        }
        .filterButton {
            color: var(--v-primary-base);
            text-decoration: underline;
        }
        .switch {
            position: relative;
            top:0;
            right:0;
            height:36px;
            display:flex;
            align-items:center;
            .v-input__slot {
                display:flex;
                align-items: center;
                min-height:unset !important;
                .v-input--selection-controls__input {
                    margin-right:4px !important;
                }
                .v-label {
                    top:unset !important;
                    font-size: 14px !important;
                }
            }
        }
        .iconTodo {
            //border: 2px solid var(--v-primary-base) !important;
        }
        .btn-icon {
            padding:0 !important;
            margin-left:10px;
        }
        .type-phone {
            text-transform: uppercase;
        }
        .v-tooltip {
            display:none !important;
        }
        .heading-filters {
            display:flex;
            justify-content: space-between;
            align-items:center;

            .input {
                //margin-left: 10px;
                min-width:300px !important;
                @media (-webkit-device-pixel-ratio: 1.25) {
                    min-width:250px !important;
                }
            }
            .v-btn__content {
                font-size:1em;
                @media (-webkit-device-pixel-ratio: 1.25) {
                    font-size:0.9em;
                }
            }
            .v-input__control{
                min-height: 30px !important;
            }
            .v-input__slot{
                min-height: 30px !important;
            }
        }
        .no-filters {
            .v-expansion-panel-header {
                cursor: default !important;
            }

        }
        .selectAll {
            display:flex;
            align-items: center;
            .v-input__control {
                flex-direction: row !important;
                flex-wrap:unset !important;
                .v-input__slot {
                    margin-bottom:0 !important;
                }
            }
        }
        .action-buttons {
            display:flex;
            justify-content: flex-end;
        }
        .v-data-table__wrapper {
            tbody {
                tr {
                    cursor:pointer;
                }
            }
        }
        .icons-datatable {
            text-align:right;
        }
        .v-expansion-panel-content__wrap {
            padding:0 !important;
            text-align:right;
            display:flex;
        justify-content: flex-end;
        .filter {

            margin-left:10px;
        }
    }
    .headingFiltersSelect {
        position:relative;
        .v-menu__content {
            max-width: unset !important;
        }
    }
    .dateRangeFilter {
        position:relative;
    }
    .countSelectedBoxes {
        position:absolute; 
        font-size:10px; 
        left:25px; 
        width:150px;
    }
    .v-data-footer {
        margin-right:0 !important;
    }
    .standardColHeader {
        padding-top:10px;
    }
    tr.mainContact {
        background-color: #e7eff6 !important;
    }
    tr.mainContact:hover {
        background-color: #e7eff6 !important;
    }
    .btnFlat {
        margin-top:14px !important;
        margin-right:24px !important;
    }
    .contextMenu{
        position: fixed;
        z-index: 999;
        outline: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        cursor: pointer;
        .v-list-item--link:hover {
            border-radius:4px;
            //background-color: rgba(255 , 41,135,0.6) !important;
            //color: #FFF!important;
        }
    }
    .innerAlert {
        padding: 0px 4px;
        border-radius: 5px;
    }
}
</style>
